import { getAvailableLanguages } from "@/config/locales"
import { useListig } from "@/hooks/useListig"
import { useLocale } from "@/hooks/useLocale"
import SSRIcon from "@ingka/ssr-icon"
import globe from "@ingka/ssr-icon/paths/globe"
import { useSwitchLanguage } from "@/hooks/useSwitchLanguage"
import { Option } from "@ingka/select"
import { useEffect, useState } from "react"

export const LanguageSwitcher = () => {
  const { session, updateLanguage } = useListig()
  const { market } = useLocale()
  const { switchLanguage } = useSwitchLanguage()

  const currentPath = location.pathname
  const currentLang = currentPath.split("/")[2]

  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    currentLang,
  )

  useEffect(() => {
    if (selectedOption && !updateLanguage.isLoading) {
      switchLanguage(selectedOption)
    }
  }, [selectedOption, switchLanguage, updateLanguage.isLoading])

  const availableLanguages = getAvailableLanguages(
    market,
    session.businessUnitCode,
  )

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value

    // make a call to listig to update language in db
    updateLanguage.update(newLanguage)

    setSelectedOption(newLanguage)
  }

  return (
    <>
      {availableLanguages.length > 1 && (
        <div className="flex justify-center item-center items-center gap-1">
          <SSRIcon paths={globe} />
          <select
            data-cy="language-switcher"
            id="language-switcher"
            onChange={handleChange}
            value={selectedOption}
            className="text-sm font-bold text-gray-500 bg-white appearance-none"
          >
            {availableLanguages.map((lang) => {
              return (
                <Option name={lang.toUpperCase()} value={lang} key={lang} />
              )
            })}
          </select>
        </div>
      )}
    </>
  )
}
