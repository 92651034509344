import { sendKepsEvent } from "../keps"

export function sendChangeTimeSlotEvent(
  eventLocation: "order_page" | "order_details",
) {
  sendKepsEvent({
    event_location: eventLocation,
    event_name: "change_timeslot",
  })
}
