import { FullServeProduct } from "@/types/product/categorizedProduct"
import { AnalyticsEvent as KepsAnalyticsEvent } from "@upptacka/keps-schema"
import { sendKepsEvent } from "../keps"

export function sendOrderCreatedEvent(
  orderNo: string,
  orderNoSource: string,
  products: FullServeProduct[],
  vpcIncluded?: boolean,
  isCaptureProfile?: boolean,
) {
  const kepsItems: KepsAnalyticsEvent["event_items"] = products.map(
    (product) => ({
      item_no: product.info.no,
      item_quantity: product.quantity,
      item_type: product.info.type,
      item_sales_method: "full_serve",
      item_price:
        product.price.family?.inclTax ?? product.price.regular.inclTax,
      item_currency: product.price.currency,
    }),
  )

  sendKepsEvent({
    event_location: isCaptureProfile
      ? "tax_point_delivery_order_confirmation"
      : "list",
    event_name: "order",
    event_detail: "created",
    event_payload: JSON.stringify({
      orderNo,
      orderNoSource,
      vpc_included: vpcIncluded,
    }),
    event_items: kepsItems,
  })
}
