import Modal, {
  ModalBody,
  ModalHeader,
  ModalFooter,
  Sheets,
} from "@ingka/modal"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"
import { AddToExistingOrder } from "../full-serve/AddToExistingOrder"
import { sendExistingOrderModalEvent } from "@/analytics/events/sendExistingOrderModalEvent"
import { PipProduct } from "@/types/product/pip"
import { useEffect, useState } from "react"
import { TimeSlots } from "../full-serve/TimeSlots"
import { TimeSlot } from "@/types/order"
import { useTimeSlotManagement } from "@/hooks/useTimeSlotManagement"

type AddToExistingOrderModalProps = {
  products: PipProduct[]
  onClose: () => void
  onContinue: (timeSlot?: TimeSlot) => void
  showing: boolean
  orderNo: string
  entryPoint?: "handover" | "list"
  orderTimeSlotId?: string
  timeSlots: TimeSlot[] | null
}

export const AddToExistingOrderModal: React.FC<
  AddToExistingOrderModalProps
> = ({
  products,
  onClose,
  onContinue,
  showing,
  orderNo,
  entryPoint = "list",
  orderTimeSlotId,
  timeSlots,
}) => {
  const { t } = useTranslation()
  const isTimeSlotManagementEnabled = useTimeSlotManagement()

  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState<
    string | undefined
  >(undefined)
  const [showTimeSlotsModal, setShowTimeSlotsModal] = useState<boolean>(false)

  const currentTimeSlot = timeSlots?.find(
    (timeSlot) => timeSlot.id === orderTimeSlotId,
  )
  const mustSelectNewTimeSlot = isTimeSlotManagementEnabled && !currentTimeSlot

  const onCancel = () => {
    setShowTimeSlotsModal(false)
    onClose()
  }

  useEffect(() => {
    if (timeSlots?.length) {
      setSelectedTimeSlotId(timeSlots[0]?.id)
    }
  }, [timeSlots])

  return (
    <Modal
      visible={showing}
      handleCloseBtn={() => {
        sendExistingOrderModalEvent("cross_clicked")
        onCancel()
      }}
    >
      <Sheets
        size="large"
        header={<ModalHeader closeBtnClick={onCancel} />}
        footer={
          showTimeSlotsModal ? (
            <></>
          ) : (
            <ModalFooter className="px-6 pt-0">
              {entryPoint === "handover" && (
                <Button
                  type="secondary"
                  text={t("mobile.maybe-later")}
                  onClick={() => {
                    sendExistingOrderModalEvent("maybe_later", orderNo)
                    onCancel()
                  }}
                  data-cy="maybe-later-button"
                />
              )}
              <Button
                type="emphasised"
                text={t("mobile.add-to-order-button")}
                onClick={() => {
                  if (mustSelectNewTimeSlot) {
                    setShowTimeSlotsModal(true)
                  } else {
                    sendExistingOrderModalEvent("add_to_order", orderNo)
                    onContinue(currentTimeSlot)
                  }
                }}
                data-cy="add-to-order-button"
              />
            </ModalFooter>
          )
        }
      >
        <ModalBody>
          {showTimeSlotsModal && timeSlots ? (
            <TimeSlots
              selectedTimeSlotId={selectedTimeSlotId}
              setSelectedTimeSlotId={setSelectedTimeSlotId}
              timeSlots={timeSlots}
              onContinue={() => {
                const selectedTimeSlot = timeSlots.find(
                  (timeSlot) => timeSlot.id === selectedTimeSlotId,
                )

                sendExistingOrderModalEvent("add_to_order", orderNo)
                onContinue(selectedTimeSlot)
              }}
              buttonText={t("common.done")}
            />
          ) : (
            <AddToExistingOrder
              products={products}
              orderNo={orderNo}
              entryPoint={entryPoint}
            />
          )}
        </ModalBody>
      </Sheets>
    </Modal>
  )
}
