import { createListigList } from "@/api/listig"
import { useAuth } from "@/hooks/useAuth"
import { useParseHandover } from "@/hooks/useHandoverQuery"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useLocalNavigation } from "./useLocalNavigation"
import { sendStartSessionEvent } from "@/analytics/events/sendStartSessionEvent"
import useVisit, { EntryPoint } from "./useVisit"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { sendOpenPipEvent } from "@/analytics/events/sendOpenPipEvent"
import { useListig } from "./useListig"
import { isActive } from "@/utils/listig"
import { useKortisId } from "./useKortis"
import { ProductType } from "@/types/product/products"
import { useIsOnline } from "./useIsOnline"
import { setActiveList } from "@/utils/localStorage"
import { useGetOrder } from "./useGetOrder"
import { NoServiceError } from "@/utils/errors"

/**
 * If handover is items handover,
 * create a list and navigate to that list.
 */
export const useCreateList = (entryPoint: EntryPoint) => {
  const handover = useParseHandover(entryPoint)
  const { oAuthToken } = useAuth()
  const { market, language } = useParams<{ market: string; language: string }>()
  const { setVisitedPage, setVisitCounter, clearVisitedPage } = useVisit()
  const { list, isLoading, addProducts, addOrder } = useListig()
  const { isOnline, isLoading: isOnlineLoading } = useIsOnline(handover.storeNo)
  const [handoverCompleted, setHandoverCompleted] = useState(false)

  // Fetch the order and check the status
  const {
    order,
    error: getOrderError,
    isLoading: getOrderLoading,
  } = useGetOrder(
    "orderNo" in handover ? handover.orderNo : undefined,
    "orderNoSource" in handover ? handover.orderNoSource : undefined,
    market,
    language,
    handover.storeNo,
  )

  const navigateHandover = useNavigateHandover(
    market,
    language,
    "id" in handover ? handover.id : undefined,
    "type" in handover ? handover.type : undefined,
  )

  const mutation = useMutationWithErrorHandling(
    (input: {
      market: string
      language: string
      token: string
      isOnline: boolean
    }) =>
      createListigList(
        "items" in handover && handover.items ? handover.items : [],
        input.market,
        input.language,
        { code: handover.storeNo, type: input.isOnline ? "ru" : "sto" },
        input.token,
        handover.source,
      ),
    {
      onSuccess: ({ id: listId }) => {
        setActiveList(listId)
        if (entryPoint === "items") {
          sendStartSessionEvent(
            handover.source,
            "item_handover",
            setVisitCounter,
            undefined,
            handover.sessionId ?? undefined,
          )
        } else if (
          entryPoint === "order" &&
          "orderNo" in handover &&
          "orderNoSource" in handover
        ) {
          // Adding the order to the new list
          addOrder.add(
            listId,
            handover.orderNo ?? null,
            handover.orderNoSource ?? null,
            order?.items?.map((item) => ({
              id: item.info.no,
              type: item.info.type,
              quantity: item.quantity,
            })) ?? null,
            false,
          )
          setVisitedPage("fullServeOnboarding")
        } else {
          sendStartSessionEvent(
            handover.source,
            "empty_list",
            setVisitCounter,
            undefined,
            handover.sessionId ?? undefined,
          )
        }

        navigateHandover(
          entryPoint,
          listId,
          "id" in handover ? handover.id : undefined,
        )
      },
    },
  )

  const mutate = mutation.mutate
  useEffect(() => {
    if (getOrderLoading) return

    if (
      entryPoint === "order" &&
      (getOrderError || order?.orderStatus === "CANCELLED")
    ) {
      throw new NoServiceError("INVALID_ORDER")
    }

    if (isLoading || isOnlineLoading || handoverCompleted) return

    setHandoverCompleted(true)

    if (market && language && oAuthToken) {
      if (isActive(list) && list.businessUnit.code === handover.storeNo) {
        if (entryPoint === "items" && "items" in handover && handover.items) {
          // add products to existing active list
          addProducts.add(handover.items)
        }

        clearVisitedPage("fullServeExistingOrder")
        sendStartSessionEvent(
          handover.source,
          "item_handover",
          setVisitCounter,
          undefined,
          handover.sessionId ?? undefined,
        )

        // add order to list
        if (
          entryPoint === "order" &&
          "orderNo" in handover &&
          "orderNoSource" in handover
        ) {
          const orderInList = list.orders?.find(
            (order) => order.orderNo === handover.orderNo,
          )
          if (!orderInList) {
            // Adding the order to the existing list if it is not already there
            addOrder.add(
              list.id,
              handover.orderNo ?? null,
              handover.orderNoSource ?? null,
              order?.items?.map((item) => ({
                id: item.info.no,
                type: item.info.type,
                quantity: item.quantity,
              })) ?? null,
              false,
            )
          }
        }

        navigateHandover(
          entryPoint,
          list.id,
          "id" in handover ? handover.id : undefined,
        )
      } else {
        // create a new list
        mutate({ market, language, token: oAuthToken, isOnline: !!isOnline })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mutate,
    market,
    language,
    oAuthToken,
    isLoading,
    isOnlineLoading,
    handoverCompleted,
    getOrderLoading,
    getOrderError,
  ])
}

function useNavigateHandover(
  market?: string,
  language?: string,
  handoverId?: string,
  productType?: ProductType,
) {
  const { navigate } = useLocalNavigation()
  const { visited } = useVisit()
  const { createKortisId } = useKortisId()

  return async (entryPoint: string, listId: string, productNo?: string) => {
    if (entryPoint === "product" && handoverId) {
      sendOpenPipEvent(handoverId, "scanner")
    }

    const kortisResponse =
      market && language
        ? await createKortisId(market, language, listId)
        : undefined
    const shortIdParam = kortisResponse
      ? new URLSearchParams({ shortId: kortisResponse.shortId })
      : undefined

    if (entryPoint === "product" && productNo && productType) {
      navigate(
        {
          path: "product",
          productNo,
          productType,
          listId,
        },
        undefined,
        shortIdParam,
      )
    } else {
      navigate(
        {
          path: entryPoint === "new" && !visited.welcome ? "welcome" : "list",
          listId,
        },
        { replace: true },
        shortIdParam,
      )
    }
  }
}
