import { getFloorNumber } from "./getFloorNumber"
import { SalesMethodsType } from "./getSalesLocation"

export function getFloorForChildItems(
  salesMethods: SalesMethodsType,
  methodKey: "self-serve" | "market-hall" | "showroom",
) {
  if (salesMethods[methodKey].length > 0) {
    const item = salesMethods[methodKey][0]
    const salesLocationId = item?.locations?.[0]?.salesLocationId
    const showFloorInformation = salesLocationId?.toUpperCase().startsWith("F")

    const floor =
      salesLocationId && showFloorInformation
        ? getFloorNumber(salesLocationId)
        : ""

    return { floor, showFloorInformation }
  }

  return { floor: "", showFloorInformation: false }
}
