import { getOrder } from "@/api/retrieveFullServeOrder"
import { useQueryWithErrorHandling } from "./useReactQuery"
import {
  CashCarryOrderResponse,
  CashCarryOrderCreatedPartialResponse,
} from "@/types/responses/buy"
import { useAuth } from "./useAuth"
import { parseCcOrder } from "@/utils/parseCcOrder"
import { isFullServeOrOnlineProduct } from "@/types/product/categorizedProduct"
import { getValidProductType } from "@/types/product/products"
import { useProducts } from "./useProducts"
import { useCo360 } from "./useCo360"
import { useWaitingTime } from "./useWaitingTime"

/**
 * Gets an order and its products
 */
export function useGetOrder(
  orderNo?: string,
  orderNoSource?: string,
  market?: string,
  language?: string,
  buCode?: string,
) {
  const { oAuthToken } = useAuth()

  const isCo360Enabled = useCo360()
  const waitingTimeEnabled = useWaitingTime()

  const hasAllQueryParameters =
    !!orderNo && !!orderNoSource && !!language && !!market && !!oAuthToken

  const {
    data: order,
    error,
    isLoading,
  } = useQueryWithErrorHandling<
    CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse
  >(
    ["order", orderNo],
    () =>
      hasAllQueryParameters
        ? getOrder({
            orderNo,
            language,
            market,
            orderNoSource,
            kongToken: oAuthToken,
            isCo360Enabled: isCo360Enabled ?? false,
            showTimeEstimate: waitingTimeEnabled ?? false,
          })
        : Promise.reject(new Error("Missing fields")),
    {
      enabled: hasAllQueryParameters,
      retry: 3,
    },
    "warning",
  )

  const { products, isLoading: isLoadingProducts } = useProducts(
    buCode,
    order && "orderStatus" in order
      ? order.articles.map((art) => ({
          id: art.itemId,
          type: getValidProductType(art.itemType),
          quantity: art.quantity,
        }))
      : undefined,
  )
  const items = products?.filter(isFullServeOrOnlineProduct)

  const instoreOrder = order ? parseCcOrder(order, items) : undefined

  return {
    order: instoreOrder,
    error,
    isLoading: isLoading || !!(order && isLoadingProducts),
  }
}
