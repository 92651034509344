import { ListCreator } from "@/components/ListCreator"
import { ReactElement } from "react"
import { Route, Routes } from "react-router"

export const SessionRouter: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  return (
    <Routes>
      <Route path="order" element={<ListCreator entryPoint="order" />} />
      <Route path="new" element={<ListCreator entryPoint="new" />} />
      <Route path="items" element={<ListCreator entryPoint="items" />} />
      <Route path="product" element={<ListCreator entryPoint="product" />} />
      <Route path="list/:listId/*" element={children} />
      <Route path="*" element={children} />
    </Routes>
  )
}
