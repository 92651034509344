import { SlideUpModal } from "../modals/SlideUpModal"
import { useState } from "react"
import { TimeSlots } from "./TimeSlots"
import { TimeSlot } from "@/types/order"
import { useTranslation } from "react-i18next"

export const TimeSlotsModal: React.FC<{
  timeSlots: TimeSlot[]
  chosenTimeSlotId?: string
  show: boolean
  onClose: () => void
  onContinue: (timeSlotId: string) => void
}> = ({ timeSlots, chosenTimeSlotId, show, onClose, onContinue }) => {
  const { t } = useTranslation()

  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState<
    string | undefined
  >(chosenTimeSlotId)

  return (
    <SlideUpModal
      onClose={() => {
        onClose()
        setSelectedTimeSlotId(chosenTimeSlotId)
      }}
      showing={show}
    >
      <TimeSlots
        selectedTimeSlotId={selectedTimeSlotId}
        setSelectedTimeSlotId={setSelectedTimeSlotId}
        timeSlots={timeSlots}
        onContinue={onContinue}
        buttonText={t("common.done")}
      />
    </SlideUpModal>
  )
}
