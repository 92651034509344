import { TimeSlot } from "@/types/order"
import { TaxAddress } from "@/types/responses/buy"
import { fetchWithErrorHandling } from "@/utils/fetch"

interface Props {
  market: string
  language: string
  storeNo: string
  orderNo: string
  orderNoSource: string
  kongToken: string
  timeSlot: TimeSlot
  taxAddress?: TaxAddress
}

export const updateTimeSlot = ({
  market,
  language,
  storeNo,
  orderNo,
  orderNoSource,
  kongToken,
  timeSlot,
  taxAddress,
}: Props): Promise<TimeSlot[]> => {
  return fetchWithErrorHandling(
    "BUY_UPDATE_TIME_SLOT",
    `${process.env.BUY_API_URL}/time-slots/${market}/${language}/${storeNo}/${orderNo}/${orderNoSource}`,
    {
      headers: {
        authorization: `Bearer ${kongToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ timeSlot, taxAddress }),
    },
  )
}
