import { useState } from "react"

const storageKey = "visit"

interface Visited {
  entryPoint: EntryPoint
  counter: number
  welcome: boolean
  list: boolean
  add: boolean
  checkout: boolean
  product: boolean
  fullServeOnboarding: boolean
  fullServeSurvey: boolean
  fullServeExistingOrder: boolean
  appPromotion: boolean
  mobileFeedback: boolean
}

export type EntryPoint = "list" | "items" | "product" | "new" | "order"
type Page = keyof Omit<Visited, "entryPoint" | "counter">

const getLocalStorage = (): Visited =>
  JSON.parse(localStorage.getItem(storageKey) || "null") || {}

const updateLocalStorage = (prop: Record<string, Visited[keyof Visited]>) =>
  localStorage.setItem(
    storageKey,
    JSON.stringify({ ...getLocalStorage(), ...prop }),
  )

export default function useVisit() {
  const [visited, setVisited] = useState<Visited>({
    ...getLocalStorage(),
    welcome: true,
    list: true,
    checkout: true,
    product: true,
  })

  const setEntryPoint = (entryPoint: EntryPoint) => {
    updateLocalStorage({ entryPoint })
    setVisited(getLocalStorage())
  }

  const setVisitCounter = () => {
    const visited = getLocalStorage()
    updateLocalStorage({ counter: visited.counter ? visited.counter + 1 : 1 })
    setVisited(getLocalStorage())
  }

  const setVisitedPage = (page: Page) => {
    updateLocalStorage({ [page]: true })
    setVisited(getLocalStorage())
  }

  const clearVisitedPage = (page: Page) => {
    updateLocalStorage({ [page]: false })
    setVisited(getLocalStorage())
  }

  return {
    visited,
    setEntryPoint,
    setVisitCounter,
    setVisitedPage,
    clearVisitedPage,
  }
}
