import { TimeSlot } from "@/types/order"

// the time slot can be changed if there is more than one time slot,
// or if there is one time slot but it is not the same as the selected one
export const canChangeTimeSlot = (
  changeTimeSlotEnabled: boolean,
  timeSlots: TimeSlot[] | undefined,
  selectedTimeSlot: TimeSlot,
): boolean =>
  changeTimeSlotEnabled &&
  !!timeSlots?.length &&
  !(timeSlots.length === 1 && timeSlots[0]?.id === selectedTimeSlot.id)
