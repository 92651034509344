export function getFloorNumber(locationId: string) {
  const upperId = locationId.toUpperCase()

  if (upperId.startsWith("FB")) {
    return "B" + upperId.charAt(2)
  } else if (upperId.startsWith("F")) {
    return (+upperId.slice(1, 3)).toString() ?? "0"
  } else {
    return "0"
  }
}
