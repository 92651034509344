import { TimeSlot } from "@/types/order"
import { sendKepsEvent } from "../keps"
import { getTimeFromLocalDateTimeWithoutTimezone } from "@/utils/formatDateTime"

export function sendTimeSlotSelectEvent(
  timeSlot: TimeSlot,
  index: number,
  timeslotOptions: number,
) {
  sendKepsEvent({
    event_location: "timeslot_options",
    event_name: "timeslot_select",
    event_payload: JSON.stringify({
      order_timeslot: index,
      timeslot_time: `${getTimeFromLocalDateTimeWithoutTimezone(timeSlot.fromDateTime)} - ${getTimeFromLocalDateTimeWithoutTimezone(timeSlot.toDateTime)}`,
      timeslot_options: timeslotOptions.toString(),
      is_default: index === 0,
    }),
  })
}
