import {
  TSM_LOCATIONS,
  TSM_ENABLED,
  TSM_UPDATE_ENABLED,
  TSM_UPDATE_LOCATIONS,
} from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { isEnabled } from "@/utils/isEnabled"

/**
 * @returns true if time slot management is enabled in kiosk/store/market or from params
 */
export function useTimeSlotManagement() {
  const { market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode, source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined

  return isEnabled({
    market,
    businessUnitCode,
    ukid,
    ENABLED: TSM_ENABLED,
    ENABLED_LOCATIONS: TSM_LOCATIONS,
  })
}

/**
 * @returns true if time slot management update is enabled in kiosk/store/market or from params
 */
export function useTimeSlotManagementUpdate() {
  const { market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode, source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined

  return isEnabled({
    market,
    businessUnitCode,
    ukid,
    ENABLED: TSM_UPDATE_ENABLED,
    ENABLED_LOCATIONS: TSM_UPDATE_LOCATIONS,
  })
}
