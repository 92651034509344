import { fetchWithErrorHandling } from "@/utils/fetch"

type Address = {
  addressLine1: string
  postalCode: string
  city: string
  cityDefault: string
  state: string
  stateCode: string
}

export const getCompleteAddress = (
  market: string,
  language: string,
  postCode: string,
): Promise<Address> => {
  return fetchWithErrorHandling(
    "GET_COMPLETE_ADDRESS",
    `${process.env.ADDRESS_AUTOCOMPLETE_API_URL}/autocomplete`,
    {
      headers: {
        "x-client-id": process.env.ADDRESS_AUTOCOMPLETE_CLIENT_ID ?? "",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        country: market.toLocaleLowerCase(),
        language: language.toLocaleLowerCase(),
        postCode: postCode,
      }),
    },
  )
}
