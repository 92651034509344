import { getCompleteAddress } from "@/api/getCompleteAddress"
import { useQueryWithErrorHandling } from "./useReactQuery"

export const useGetCompleteAddress = (
  market: string,
  language: string,
  postCode: string,
) => {
  const completeAddressQuery = useQueryWithErrorHandling(
    [market, language, postCode],
    () =>
      market && language && postCode
        ? getCompleteAddress(market, language, postCode)
        : Promise.reject("Missing data"),
    {
      enabled: !!market && !!language && !!postCode,
      gcTime: Infinity,
      staleTime: Infinity,
    },
  )

  return {
    data: completeAddressQuery.data,
    isLoading: completeAddressQuery.isFetching,
  }
}
