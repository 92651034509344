import { useTranslation } from "react-i18next"
import {
  FullServeProduct,
  isFullServeArticle,
} from "@/types/product/categorizedProduct"
import ProductCard from "../list/product-card/ProductCard"
import { TFunction } from "i18next"
import FullServeChildItem from "../list/product-card/FullServeChildItem"

interface FullServeProductsProps {
  products: FullServeProduct[]
}

const FullServeProducts: React.FC<FullServeProductsProps> = ({ products }) => {
  const { t } = useTranslation()

  return (
    <>
      {products.map((product) => (
        <div key={product.info.no} className="my-0.5">
          <div id={"FULL_SERVE_" + product.info.no}>
            <ProductCard
              availability={product.stock.cashCarry}
              type={
                product.info.type === "ART"
                  ? "FULL_SERVE"
                  : `${product.info.type}_PARENT`
              }
              salesMethod="FULL_SERVE"
              product={{ ...product, isOrdered: false }}
              partiallyAvailableWarning={getPartiallyAvailableText(product, t)}
            />
            {!isFullServeArticle(product) &&
              product.info.childItems.map((childItem) => (
                <FullServeChildItem
                  key={childItem.info.no}
                  product={childItem}
                  isSplit={product.info.isSplit}
                />
              ))}
          </div>
        </div>
      ))}
    </>
  )
}

export default FullServeProducts

function getPartiallyAvailableText(product: FullServeProduct, t: TFunction) {
  return "isSplit" in product.info && product.info.isSplit
    ? t("Interstitial-ProductListCard-collect-full-serve-of-split-spr")
    : undefined
}
