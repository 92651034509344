export const getTimeFromLocalDateTimeWithoutTimezone = (
  localDateTimeString: string,
): string => {
  const dateTime = new Date(localDateTimeString)

  // we use getUTC because localDateTimeString is missing timezone and therefore seen as the UTC time
  const hours = dateTime.getUTCHours()
  const minutes = dateTime.getUTCMinutes()

  const formattedMinutes =
    minutes !== undefined
      ? minutes < 10
        ? `0${minutes}`
        : `${minutes}`
      : undefined

  return `${hours}:${formattedMinutes}`
}
