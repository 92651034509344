import { ListProduct } from "@/types/product/listProduct"
import {
  AddItemsToOrderBodySchema,
  CreateCashCarryOrderBody,
} from "@/types/responses/buy"

export function formatCcOrderListigItems(
  products: ListProduct[],
): CreateCashCarryOrderBody["listigOrderItems"] {
  return products.map((product) => ({
    itemId: product.info.no,
    type: product.info.type,
    quantity: product.quantity ?? 0,
  }))
}

export function formatCcOrderItems(
  products: ListProduct[],
): CreateCashCarryOrderBody["items"] | AddItemsToOrderBodySchema["items"] {
  let formattedItems:
    | CreateCashCarryOrderBody["items"]
    | AddItemsToOrderBodySchema["items"] = []

  products.forEach((product) => {
    if (product.info.type === "ART") {
      const duplicate = formattedItems.find(
        (item) => item.itemId === product.info.no,
      )

      if (duplicate) {
        duplicate.quantity += product.quantity
      } else {
        formattedItems.push({
          itemId: product.info.no,
          type: product.info.type,
          quantity: product.quantity || 0,
          measurement: "PIECES",
        })
      }
    } else {
      formattedItems = formattedItems.concat(
        formatCcOrderItems(
          product.info.childItems.map((e) => ({
            ...e,
            quantity: e.quantity,
          })),
        ),
      )
    }
  })

  return formattedItems
}
