import { updateListigProduct } from "@/api/listig"
import { useAuth } from "@/hooks/useAuth"
import { ListigList } from "@/types/listig"
import { useCallback } from "react"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useQueryClient } from "@tanstack/react-query"
import { ProductType } from "@/types/product/products"

/**
 * Provides a function to update product quantity to the list,
 * will automatically refetch the list afterwards.
 * @returns a function to update a product in the listig list
 */
export function useUpdateProduct(listId: string | null) {
  const { oAuthToken } = useAuth()
  const queryClient = useQueryClient()

  /** mutation to update a product in listig and refresh on success */
  const mutation = useMutationWithErrorHandling(
    (input: { itemNo: string; itemType: ProductType; quantity: number }) => {
      return listId && oAuthToken
        ? updateListigProduct(
            listId,
            input.itemNo,
            input.itemType,
            input.quantity,
            oAuthToken,
          )
        : Promise.reject(new Error("Missing fields"))
    },
    {
      onSuccess: (list: ListigList) =>
        queryClient.invalidateQueries({
          queryKey: ["listig", list.id.toString()],
        }),
    },
  )

  const mutate = mutation.mutate
  const updateProduct = useCallback(
    (
      product: { itemNo: string; itemType: ProductType; quantity: number },
      options?: {
        onSuccess?: (data: ListigList) => void
        onError?: (e: unknown) => void
      },
    ) => mutate(product, options),
    [mutate],
  )

  return {
    /** update product and automatically reload list */
    update: updateProduct,

    /** error when updating product */
    error: mutation.error,
  }
}
