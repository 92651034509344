import useChecklist from "@/hooks/useChecklist"
import {
  SelfServeProduct,
  isSelfServeArticle,
} from "@/types/product/categorizedProduct"
import AnimateHeight from "react-animate-height"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import ListSection from "../list/ListSection"
import ProductCard from "../list/product-card/ProductCard"
import ChildItem from "../list/product-card/ChildItem"

interface SelfServeSectionProps {
  products: SelfServeProduct[]
}

const SelfServeSection: React.FC<SelfServeSectionProps> = (props) => {
  const { t } = useTranslation()
  const {
    checklist,
    toggleProductChecked,
    isAllChildrenChecked,
    unCheckChildren,
  } = useChecklist()

  return (
    <ListSection
      title={t("Interstitial-pickup-self-serve")}
      subTitle={t(
        props.products.length <= 1 ? "common.item" : "common.item_plural",
        {
          count: props.products.length,
        },
      )}
    >
      {props.products.map((product) => (
        <div
          key={product.info.no + product.isOrdered}
          className="mt-2 first:mt-0"
        >
          {isSelfServeArticle(product) ? (
            <ProductCard
              packages={product.info.packaging?.packages}
              availability={product.stock.cashCarry}
              type="SELF_SERVE"
              salesMethod="SELF_SERVE"
              aisle={product.locations[0]?.aisle ?? ""}
              bin={product.locations[0]?.bin ?? ""}
              checklist={{
                isChecked: !!checklist[product.info.no],
                onCheck: () =>
                  toggleProductChecked(
                    product.info.no,
                    product.quantity,
                    product.price,
                    "self_serve",
                  ),
              }}
              product={product}
            />
          ) : (
            <div id={"SELF_SERVE_" + product.info.no}>
              <ProductCard
                availability={product.stock.cashCarry}
                partiallyAvailableText={getPartiallyAvailableText(product, t)}
                type={`${product.info.type}_PARENT`}
                salesMethod="SELF_SERVE"
                checklist={{
                  isChecked: isAllChildrenChecked(
                    product.info.no,
                    product.info.childItems.length,
                  ),
                  onCheck: () => unCheckChildren(product.info.no),
                }}
                product={product}
              />
              <AnimateHeight
                duration={300}
                height={
                  isAllChildrenChecked(
                    product.info.no,
                    product.info.childItems.length,
                  )
                    ? 0
                    : "auto"
                }
                disableDisplayNone
              >
                {product.info.childItems.map((childItem) => (
                  <ChildItem
                    key={childItem.info.no}
                    product={childItem}
                    isSplit={product.info.isSplit}
                    checklist={{
                      isChecked:
                        !!checklist[product.info.no + "." + childItem.info.no],
                      onCheck: () =>
                        toggleProductChecked(
                          product.info.no + "." + childItem.info.no,
                          childItem.quantity,
                          childItem.price,
                          "self_serve",
                        ),
                    }}
                  />
                ))}
              </AnimateHeight>
            </div>
          )}
        </div>
      ))}
    </ListSection>
  )
}

export default SelfServeSection

function getPartiallyAvailableText(product: SelfServeProduct, t: TFunction) {
  return "isSplit" in product.info && product.info.isSplit
    ? t(
        `Interstitial-ProductListCard-collect-self-serve-of-split-spr${
          product.info.hasFullServePart ? "" : "-generic"
        }`,
        {
          count: product.info.totalChildItems,
        },
      )
    : undefined
}
