import Button from "@ingka/button"
import { useEffect, useState } from "react"
import { TimeSlot } from "@/types/order"
import { getTimeFromLocalDateTimeWithoutTimezone } from "@/utils/formatDateTime"
import { useTranslation } from "react-i18next"
import { FullServeStatus } from "./FullServeStatusText"
import { TimeSlotsModal } from "./TimeSlotsModal"
import { useSession } from "@/hooks/useListig"
import { useGetTimeSlots } from "@/hooks/useGetTimeSlots"
import { sendChangeTimeSlotEvent } from "@/analytics/events/sendChangeTimeSlotEvent"
import { canChangeTimeSlot } from "@/utils/canChangeTimeSlot"
import {
  FullServeProduct,
  OnlineProduct,
} from "@/types/product/categorizedProduct"
import { useTimeSlotManagementUpdate } from "@/hooks/useTimeSlotManagement"

interface TimeSlotOrderViewProps {
  status?: FullServeStatus
  timeSlot: TimeSlot
  items?: (FullServeProduct | OnlineProduct)[]
  onContinue: (timeSlot: TimeSlot) => void
}

export const TimeSlotOrderView: React.FC<TimeSlotOrderViewProps> = ({
  status,
  timeSlot,
  items,
  onContinue,
}) => {
  const { t } = useTranslation()
  const { session } = useSession()
  const { data: timeSlots } = useGetTimeSlots(
    session.businessUnitCode,
    items, // TODO: change this to pass in order number instead of products?
  )

  const isTimeSlotManagementUpdateEnabled = useTimeSlotManagementUpdate()

  const [showTimeSlotModal, setShowTimeSlotModal] = useState<boolean>(false)
  const [showChangeTimeSlot, setShowChangeTimeSlot] = useState<boolean>(false)

  useEffect(() => {
    setShowChangeTimeSlot(
      canChangeTimeSlot(isTimeSlotManagementUpdateEnabled, timeSlots, timeSlot),
    )
  }, [timeSlots, timeSlot, isTimeSlotManagementUpdateEnabled])

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div>
          <div className="text-sm">{t("mobile.collection-time")}:</div>
          <div className="text-xl pb-2 pt-1">
            {getTimeFromLocalDateTimeWithoutTimezone(timeSlot.fromDateTime) +
              " - " +
              getTimeFromLocalDateTimeWithoutTimezone(timeSlot.toDateTime)}
          </div>
        </div>

        {timeSlots && showChangeTimeSlot && (
          <div>
            <Button
              type="tertiary"
              className="bg-neutral-100"
              size="small"
              onClick={() => {
                sendChangeTimeSlotEvent("order_details")
                setShowTimeSlotModal(true)
              }}
            >
              {t("common.change")}
            </Button>

            <TimeSlotsModal
              timeSlots={timeSlots}
              chosenTimeSlotId={
                timeSlots.find((ts) => ts.id === timeSlot.id)?.id ??
                timeSlots[0]?.id
              }
              show={showTimeSlotModal}
              onClose={() => setShowTimeSlotModal(false)}
              onContinue={(timeSlotId: string) => {
                const timeSlot = timeSlots.find((ts) => ts.id === timeSlotId)
                if (timeSlot) {
                  onContinue(timeSlot)
                }
                setShowTimeSlotModal(false)
              }}
            />
          </div>
        )}
      </div>

      {status === "WAITING_FOR_PAYMENT" && (
        <div className="text-sm">
          {t("mobile.pay-later-new-time", {
            time: getTimeFromLocalDateTimeWithoutTimezone(
              timeSlot.paymentCutOffDateTime,
            ),
          })}
        </div>
      )}
    </div>
  )
}
