import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal"

type SlideUpModalProps = {
  children?: React.ReactNode
  onClose: (e?: React.MouseEvent) => void
  showing: boolean
}

export const SlideUpModal: React.FC<SlideUpModalProps> = ({
  children,
  onClose,
  showing,
}) => {
  return (
    <Modal
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      visible={showing}
      handleCloseBtn={onClose}
    >
      <Sheets
        size="large"
        header={<ModalHeader closeBtnClick={onClose} />}
        footer={<div></div>}
      >
        <ModalBody>{children}</ModalBody>
      </Sheets>
    </Modal>
  )
}
