import { Variant } from "@/types/responses/find"
import React from "react"
import { useTranslation } from "react-i18next"
import { VariantButton } from "./VariantButton"
import { useProduct } from "@/hooks/useProducts"
import { getPriceDiffString } from "@/utils/getPriceDiff"
import { VisualVariationTypes } from "@/config/constants"
import { PipProduct } from "@/types/product/pip"
import { measureMap } from "@/config/locales"
import { useLocale } from "@/hooks/useLocale"

export const ProductVariant: React.FC<{
  handleVariantOnClick: (productNo: string) => void
  variant?: Variant
  currentVariantValue: string
  currentProduct: PipProduct
  variantTypeName: string
  variantTypeNameGlobal: string
}> = ({
  handleVariantOnClick,
  variant,
  currentVariantValue,
  currentProduct,
  variantTypeName,
  variantTypeNameGlobal,
}) => {
  const { t } = useTranslation()

  const { productInfo: variantProduct } = useProduct(
    variant?.productNo,
    currentProduct.info.type,
  )
  const { market } = useLocale()
  const measureUnit = measureMap[market.toLowerCase()]?.unit ?? "metric"
  const priceDiff = getPriceDiffString(
    variantProduct?.price,
    currentProduct.price,
  )

  if (!variant) return null

  let variantSizeValue

  if (variantTypeNameGlobal === "size") {
    variantSizeValue =
      variantProduct?.info.measurements?.referenceMeasurements?.[0]?.[
        measureUnit
      ]
  }
  return (
    <VariantButton
      key={variant.value}
      selected={variant.value === currentVariantValue}
      title={
        variant.value === "-"
          ? t("ProductVariantsModal-without-variant", {
              variation: variantTypeName,
            })
          : (variantSizeValue ?? variant.value)
      }
      onClick={() => {
        if (variant?.productNo) {
          handleVariantOnClick(variant.productNo)
        }
      }}
      priceDiff={priceDiff}
      image={
        VisualVariationTypes.includes(variantTypeNameGlobal)
          ? variantProduct?.media.mainImage?.href
          : ""
      }
      variantProduct={variantProduct}
    />
  )
}
