import Button from "@ingka/button"
import { ModalFooter } from "@ingka/modal"
import { useTranslation } from "react-i18next"
import OrderStatus from "./OrderStatus"
import { SlideUpModal } from "../modals/SlideUpModal"
import InputField from "@ingka/input-field"
import FormField from "@ingka/form-field"
import { Select, Option } from "@ingka/select"
import { useEffect, useState } from "react"
import { validateEmail, validatePhoneNumber } from "@/utils/validateContactData"
import { PrivacyPolicyModal } from "../modals/PrivacyPolicyModal"
import { TimeSlots } from "./TimeSlots"
import { TimeSlot } from "@/types/order"
import { sendTimeSlotCloseEvent } from "@/analytics/events/sendTimeSlotCloseEvent"
import { sendContactDetailsCloseEvent } from "@/analytics/events/sendContactDetailsCloseEvent"

/**
 * Prompt to confirm full serve order and lock list
 */
export const OrderConfirmationPrompt: React.FC<{
  show: boolean
  onContinue: (
    notification?: {
      contactMethodType: string
      contactMethodData: string
    },
    timeSlotId?: string,
  ) => void
  onCancel: () => void
  isCaptureEmailSMS: boolean
  market: string
  language: string
  timeSlots: TimeSlot[] | null
}> = ({
  show,
  onContinue,
  onCancel,
  isCaptureEmailSMS,
  market,
  language,
  timeSlots,
}) => {
  const { t } = useTranslation()

  const [selectedOption, setSelectedOption] = useState("Email")
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value === "SMS" ? "SMS" : "Email")
    setContactDataValue("")
    setShouldValidate(false)
  }

  const [contactDataValue, setContactDataValue] = useState("")
  const [isValid, setIsValid] = useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [showPolicy, setShowPolicy] = useState<boolean>(false)
  const [showCaptureAfterTimeSlots, setShowCaptureAfterTimeSlots] =
    useState<boolean>(false)

  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState<
    string | undefined
  >(undefined)

  const smsText = t("common.sms-warning")
  const emailText = t("common.email-warning")

  // don't show time slot modal if only one time slot is available
  const hideTimeSlotModal = timeSlots?.length === 1

  const showTimeSlots = !showCaptureAfterTimeSlots && timeSlots
  const showCapture = isCaptureEmailSMS || showCaptureAfterTimeSlots

  useEffect(() => {
    if (timeSlots?.length) {
      setSelectedTimeSlotId(timeSlots[0]?.id)

      if (hideTimeSlotModal) {
        setShowCaptureAfterTimeSlots(true)
      }
    }
  }, [timeSlots, hideTimeSlotModal])

  useEffect(() => {
    const validCheck =
      selectedOption === "SMS"
        ? validatePhoneNumber(contactDataValue)
        : validateEmail(contactDataValue)
    setIsValid(validCheck)
  }, [contactDataValue, selectedOption, isValid])

  const onClose = () => {
    onCancel()
    setShowCaptureAfterTimeSlots(hideTimeSlotModal)
  }

  return (
    <SlideUpModal
      onClose={() => {
        if (showTimeSlots) {
          sendTimeSlotCloseEvent()
        } else if (showCapture) {
          sendContactDetailsCloseEvent()
        }

        onClose()
      }}
      showing={show}
    >
      {showTimeSlots ? (
        <TimeSlots
          selectedTimeSlotId={selectedTimeSlotId}
          setSelectedTimeSlotId={setSelectedTimeSlotId}
          timeSlots={timeSlots}
          onContinue={() => setShowCaptureAfterTimeSlots(true)}
          buttonText={t("common.continue")}
        />
      ) : showCapture ? (
        <>
          <h2 className="text-2xl mb-12" data-cy="modal-header">
            {t("mobile.stay-informed")}
          </h2>
          <form
            onSubmit={(e) => {
              e.preventDefault()

              if (isValid) {
                onContinue(
                  {
                    contactMethodType: selectedOption,
                    contactMethodData: contactDataValue,
                  },
                  selectedTimeSlotId,
                )
                setShowCaptureAfterTimeSlots(hideTimeSlotModal)
              } else {
                setShouldValidate(true)
              }
            }}
          >
            <>
              <p className="text-sm pb-4">{t("mobile.contact-for-updates")}</p>
              <div className="flex flex-row gap-1 mb-0 pb-0">
                <Select
                  hintText=""
                  id="contact-selector"
                  onChange={handleChange}
                  value={selectedOption === "SMS" ? "SMS" : "Email"}
                  className="flex-col w-1/3"
                  data-cy="contact-selector"
                >
                  <Option name="SMS" value="SMS" />
                  <Option name="Email" value="Email" />
                </Select>
                <FormField
                  className="w-2/3 mb-1"
                  valid={isValid}
                  shouldValidate={shouldValidate}
                  validation={{
                    msg: selectedOption === "SMS" ? smsText : emailText,
                    id: selectedOption === "SMS" ? "SMS" : "Email",
                    type: "error",
                  }}
                  data-cy="form-field"
                >
                  {selectedOption === "SMS" ? (
                    <InputField
                      id="SMS"
                      type="number"
                      value={contactDataValue}
                      onChange={(e) => setContactDataValue(e.target.value)}
                      inputMode="numeric"
                      data-cy="input-field-sms"
                    />
                  ) : (
                    <InputField
                      id="Email"
                      type="text"
                      value={contactDataValue}
                      onChange={(e) => setContactDataValue(e.target.value)}
                      inputMode="email"
                      data-cy="input-field-email"
                    />
                  )}
                </FormField>
              </div>
              <p className="text-xs text-gray-400 mt-0 mb-10">
                {t("mobile.show-privacy-policy")}&nbsp;
                <span onClick={() => setShowPolicy(true)} className="underline">
                  {t("common.privacy-policy-anchor-text")}
                </span>
              </p>
              <PrivacyPolicyModal
                showPolicy={showPolicy}
                onClose={() => setShowPolicy(false)}
                market={market}
                language={language}
              />
            </>

            <ModalFooter className="px-0">
              <Button text={t("mobile.not-yet")} onClick={onClose} />
              <Button
                data-cy="confirm-order-button"
                type="emphasised"
                text={t("common.place-order")}
                htmlType="submit"
              />
            </ModalFooter>
          </form>
        </>
      ) : (
        <>
          <h2 className="text-2xl mb-12" data-cy="modal-header">
            {t("mobile.ready-to-order")}
          </h2>
          <div className="mt-6">
            <OrderStatus />
            <p className="text-base mb-10">{t("mobile.after-place-order")}</p>
          </div>

          <ModalFooter className="px-0">
            <Button text={t("mobile.not-yet")} onClick={onClose} />
            <Button
              data-cy="confirm-order-button"
              type="emphasised"
              text={t("mobile.place-order")}
              htmlType="button"
              onClick={() => onContinue()}
            />
          </ModalFooter>
        </>
      )}
    </SlideUpModal>
  )
}
