import { addProducts } from "@/api/listig"
import { useAuth } from "@/hooks/useAuth"
import { ListigList } from "@/types/listig"
import { useCallback } from "react"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useQueryClient } from "@tanstack/react-query"

/**
 * Provides a function to add products to the list,
 * will automatically refetch the list afterwards.
 * @returns a function to add products to listig list
 */
export function useAddProducts(listId: string | null) {
  const { oAuthToken } = useAuth()
  const queryClient = useQueryClient()

  /** mutation to add products to listig and refresh on success */
  const mutation = useMutationWithErrorHandling(
    (input: { products: ListigList["items"] }) =>
      listId && oAuthToken
        ? addProducts(listId, input.products, oAuthToken)
        : Promise.reject(new Error("Missing fields")),
    {
      onSuccess: (list: ListigList) =>
        queryClient.invalidateQueries({
          queryKey: ["listig", list.id.toString()],
        }),
    },
  )

  const mutate = mutation.mutate
  const addListigProducts = useCallback(
    (
      products: ListigList["items"],
      options?: {
        onSuccess?: (data: ListigList) => void
      },
    ) => mutate({ products }, { onSuccess: options?.onSuccess }),
    [mutate],
  )

  return {
    /** add products to list and automatically reload list */
    add: addListigProducts,
    /** error when adding products */
    error: mutation.error,
  }
}
