import {
  isFullServeArticle as isFullServeArticleUtils,
  isFullServeProduct as isFullServeProductUtils,
} from "@/utils/isFullServe"
import {
  ArtProductInfo,
  ChildArtProductInfo,
  DepartmentSalesLocation,
  FullServeSalesLocation,
  PlannerProductInfo,
  ScoProductInfo,
  SelfServeSalesLocation,
  SprProductInfo,
  StockInformation,
} from "../responses/find"
import {
  isInStock,
  isSalesProduct,
  ListArticle,
  ListChildItem,
  ListScoProduct,
  ListSalesProduct,
  isArticle,
  ListProduct,
  ListPlannerProduct,
} from "./listProduct"
import i18n from "i18next"

export type InStoreChildItem =
  | DepartmentChildItem
  | SelfServeChildItem
  | FullServeChildItem

export type InStoreArticle =
  | DepartmentArticle
  | SelfServeArticle
  | FullServeArticle

export type InStoreSalesProduct = (
  | DepartmentSalesProduct
  | SelfServeSalesProduct
  | FullServeSalesProduct
) & { stock: StockInformation }

export type InStoreScoProduct = (
  | DepartmentScoProduct
  | SelfServeScoProduct
  | FullServeScoProduct
) & { stock: StockInformation }

export type InStorePlannerProduct = (
  | DepartmentPlannerProduct
  | SelfServePlannerProduct
  | FullServePlannerProduct
) & { stock: StockInformation }

export type InStoreProduct =
  | InStoreArticle
  | InStoreSalesProduct
  | InStoreScoProduct
  | InStorePlannerProduct

export type SplitInfo =
  | {
      isSplit: true
      totalChildItems: number
      totalChildItemsQuantity: number
      hasFullServePart: boolean
    }
  | { isSplit: false }

//#region Department

export type DepartmentProduct =
  | DepartmentSalesProduct
  | DepartmentScoProduct
  | DepartmentArticle
  | DepartmentChildItem
  | DepartmentPlannerProduct

export interface DepartmentArticle extends ListArticle {
  info: ArtProductInfo
  locations: DepartmentSalesLocation[]
  stock: StockInformation
}
export interface DepartmentChildItem extends ListChildItem {
  info: ChildArtProductInfo
  locations: DepartmentSalesLocation[]
  stock: StockInformation
}

export interface DepartmentSalesProduct extends ListSalesProduct {
  info: DepartmentSprInfo & SplitInfo
  stock: StockInformation
}
interface DepartmentSprInfo extends SprProductInfo {
  childItems: DepartmentChildItem[]
}

export interface DepartmentScoProduct extends ListScoProduct {
  info: DepartmentScoInfo & SplitInfo
  stock: StockInformation
}
interface DepartmentScoInfo extends ScoProductInfo {
  childItems: DepartmentChildItem[]
}

export interface DepartmentPlannerProduct extends ListPlannerProduct {
  info: DepartmentPlannerInfo & SplitInfo
  stock: StockInformation
}
interface DepartmentPlannerInfo extends PlannerProductInfo {
  childItems: DepartmentChildItem[]
}

function isDepartment(
  article: ListArticle | ListChildItem,
): article is DepartmentArticle | DepartmentChildItem {
  return (
    !!article.locations?.[0] &&
    (article.locations[0].salesMethod === "MARKET_HALL" ||
      article.locations[0].salesMethod === "SHOWROOM")
  )
}

export function isDepartmentArticle(
  product: ListProduct,
): product is DepartmentArticle | DepartmentChildItem {
  return isArticle(product) && isDepartment(product)
}

export function isDepartmentSalesProduct(
  product: DepartmentProduct,
): product is DepartmentSalesProduct {
  return isSalesProduct(product)
}

export function DDSKitchenArticleWithLocations(
  article: InStoreArticle,
): DepartmentArticle {
  return {
    ...article,
    locations: [
      {
        salesMethod: "SHOWROOM",
        // should always be kitchen, but fallback to "contact coworker" in case of different department
        departmentName:
          article.info.businessStructure.hfb.no === "07"
            ? i18n.t("room.kitchen")
            : i18n.t("common.contact-coworker"),
        gridId: "",
        salesLocationId: "",
        rank: 1,
      },
    ],
  }
}

//#endregion

//#region SelfServe

export type SelfServeProduct =
  | SelfServeSalesProduct
  | SelfServeArticle
  | SelfServeChildItem
  | SelfServeScoProduct
  | SelfServePlannerProduct

export interface SelfServeChildItem extends ListChildItem {
  info: ChildArtProductInfo
  locations: SelfServeSalesLocation[]
  stock: StockInformation
}
export interface SelfServeArticle extends ListArticle {
  info: ArtProductInfo
  locations: SelfServeSalesLocation[]
  stock: StockInformation
}

export interface SelfServeSalesProduct extends ListSalesProduct {
  info: SelfServeSprInfo & SplitInfo
  stock: StockInformation
}
interface SelfServeSprInfo extends SprProductInfo {
  childItems: SelfServeChildItem[]
}

export interface SelfServeScoProduct extends ListScoProduct {
  info: SelfServeScoInfo & SplitInfo
  stock: StockInformation
}
interface SelfServeScoInfo extends ScoProductInfo {
  childItems: SelfServeChildItem[]
}

export interface SelfServePlannerProduct extends ListPlannerProduct {
  info: SelfServePlannerInfo & SplitInfo
  stock: StockInformation
}
interface SelfServePlannerInfo extends PlannerProductInfo {
  childItems: SelfServeChildItem[]
}

function isSelfServe(
  article: ListArticle | ListChildItem,
): article is SelfServeArticle | SelfServeChildItem {
  return (
    !!article.locations?.[0] &&
    article.locations[0].salesMethod === "SELF_SERVE"
  )
}

export function isSelfServeArticle(
  product: ListProduct,
): product is SelfServeArticle | SelfServeChildItem {
  return isArticle(product) && isSelfServe(product)
}

export function isSelfServeSalesProduct(
  product: SelfServeProduct,
): product is SelfServeSalesProduct {
  return isSalesProduct(product)
}

//#endregion

//#region FullServe

export type FullServeProduct =
  | FullServeSalesProduct
  | FullServeArticle
  | FullServeChildItem
  | FullServeScoProduct
  | FullServePlannerProduct

export interface FullServeChildItem extends ListChildItem {
  info: ChildArtProductInfo
  locations: FullServeSalesLocation[]
  stock: StockInformation
}
export interface FullServeArticle extends ListArticle {
  info: ArtProductInfo
  locations: FullServeSalesLocation[]
  stock: StockInformation
}

export interface FullServeSalesProduct extends ListSalesProduct {
  info: FullServeSprInfo & SplitInfo
  stock: StockInformation
}
interface FullServeSprInfo extends SprProductInfo {
  childItems: FullServeChildItem[]
}

export interface FullServeScoProduct extends ListScoProduct {
  info: FullServeScoInfo & SplitInfo
  stock: StockInformation
}
interface FullServeScoInfo extends ScoProductInfo {
  childItems: FullServeChildItem[]
}

export interface FullServePlannerProduct extends ListPlannerProduct {
  info: FullServePlannerInfo & SplitInfo
  stock: StockInformation
}
interface FullServePlannerInfo extends PlannerProductInfo {
  childItems: FullServeChildItem[]
}

export function isFullServeSalesProduct(
  product: FullServeProduct,
): product is FullServeSalesProduct {
  return isSalesProduct(product)
}

export function isFullServeArticle(
  product: ListProduct,
): product is FullServeArticle | FullServeChildItem {
  return isArticle(product) && !!isFullServeArticleUtils(product)
}

export const isFullServeProduct = (
  product: ListProduct,
): product is FullServeProduct => !!isFullServeProductUtils(product)

//#endregion

export const isFullServeOrOnlineProduct = (
  product: ListProduct,
): product is FullServeProduct | OnlineProduct =>
  !!isFullServeProduct(product) || !isInStock(product)

//#region Online

export type OnlineProduct =
  | OnlineSalesProduct
  | OnlineScoProduct
  | OnlineArticle
  | OnlineChildItem
  | OnlinePlannerProduct

export interface OnlineArticle extends ListArticle {
  info: ArtProductInfo
  stock: StockInformation
}
export interface OnlineChildItem extends ListChildItem {
  info: ChildArtProductInfo
  stock: StockInformation
}

export interface OnlineSalesProduct extends ListSalesProduct {
  info: OnlineSprInfo & SplitInfo
  stock: StockInformation
}
interface OnlineSprInfo extends SprProductInfo {
  childItems: OnlineChildItem[]
}

export interface OnlineScoProduct extends ListScoProduct {
  info: OnlineScoInfo & SplitInfo
  stock: StockInformation
}
interface OnlineScoInfo extends ScoProductInfo {
  childItems: OnlineChildItem[]
}

export interface OnlinePlannerProduct extends ListPlannerProduct {
  info: OnlinePlannerInfo & SplitInfo
  stock: StockInformation
}
interface OnlinePlannerInfo extends PlannerProductInfo {
  childItems: OnlineChildItem[]
}

//#endregion
