import { updateListigLanguage } from "@/api/listig"
import { useAuth } from "@/hooks/useAuth"
import { ListigList } from "@/types/listig"
import { useCallback } from "react"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useQueryClient } from "@tanstack/react-query"

/**
 * Provides a function to update the langauge for the list.
 * @returns a function to update the langauge for the listig list
 */
export function useUpdateLanguage(listId: string | null) {
  const { oAuthToken } = useAuth()
  const queryClient = useQueryClient()

  /** mutation to update the langauge for listig */
  const mutation = useMutationWithErrorHandling(
    (language: string) => {
      return listId && oAuthToken
        ? updateListigLanguage(listId, language, oAuthToken)
        : Promise.reject(new Error("Missing fields"))
    },
    {
      onSuccess: (list: ListigList) =>
        queryClient.invalidateQueries({
          queryKey: ["listig", list.id.toString()],
        }),
    },
  )

  const mutate = mutation.mutate
  const updateLanguage = useCallback(
    (
      language: string,
      options?: {
        onSuccess?: (data: ListigList) => void
        onError?: (e: unknown) => void
      },
    ) => mutate(language, options),
    [mutate],
  )

  return {
    /** update langauge for list */
    update: updateLanguage,

    /** error when updating langauge */
    error: mutation.error,
    isLoading: mutation.isPending,
  }
}
