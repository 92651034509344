import { useCcOrder } from "@/hooks/useCcOrder"
import { useLocale } from "@/hooks/useLocale"
import useVisit from "@/hooks/useVisit"
import { FullServeProduct } from "@/types/product/categorizedProduct"
import { useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { FullServeButton } from "../full-serve/FullServeButton"
import FullServeErrorMessage from "../full-serve/FullServeErrorMessage"
import FullServeProducts from "../full-serve/FullServeProducts"
import InfoBox from "../full-serve/InfoBox"
import ListSection from "../list/ListSection"
import { sendOrderCreatedEvent } from "@/analytics/events/sendOrderCreatedEvent"
import { useIsFetching, useQueryClient } from "@tanstack/react-query"
import { sendOrderButtonConfirmedEvent } from "@/analytics/events/sendOrderButtonConfirmedEvent"
import { FullServeOnboardingModal } from "../modals/FullServeOnboardingModal"
import { Notification } from "../Notification"
import SSRIcon from "@ingka/ssr-icon"
import questionMark from "@ingka/ssr-icon/paths/question-mark-circle"
import { SurveyPrompt } from "../full-serve/SurveyPrompt"
import {
  HIDE_COWORKER_SURVEY,
  SHOW_COWORKER_SURVEY_STORES,
} from "@/config/constants"
import { sendOrderButtonClickedEvent } from "@/analytics/events/sendOrderButtonClickedEvent"
import { sendOpenFullserveOnboardingEvent } from "@/analytics/events/sendFullServeOnboardingEvent"
import { OrderCard } from "../full-serve/OrderCard"
import AnimateHeight, { Height } from "react-animate-height"
import { useListig } from "@/hooks/useListig"
import { getListVpcCodes } from "@/utils/listig"
import { OrderConfirmationPrompt } from "../full-serve/OrderConfirmationPrompt"
import { useCaptureEmailSms } from "@/hooks/useCaptureEmailSms"
import { useCaptureProfile } from "@/hooks/useCaptureProfile"
import { useInstoreOrders } from "@/hooks/useInstoreOrders"
import { sendOrderButtonEditClickedEvent } from "@/analytics/events/sendOrderButtonEditClickedEvent"
import { isIsomFullServe } from "@/utils/fullServeRestrictions"
import { useEditOrders } from "@/hooks/useEditOrders"
import { AddToExistingOrderModal } from "../modals/AddToExistingOrderModal"
import { useCookieConsentVisible } from "@/hooks/useCookieConsent"
import { CreateCashCarryOrderBody } from "@/types/responses/buy"
import { useTimeSlotManagement } from "@/hooks/useTimeSlotManagement"
import { CaptureProfileModal } from "../full-serve/CaptureProfileModal"
import { useGetTimeSlots } from "@/hooks/useGetTimeSlots"
import { formatCcOrderItems } from "@/utils/formatCcOrderItems"
import { TimeSlot } from "@/types/order"
import { useGetOrder } from "@/hooks/useGetOrder"

interface FullServeSectionProps {
  products: FullServeProduct[]
}

export const FullServeSection: React.FC<FullServeSectionProps> = (props) => {
  const { t } = useTranslation()
  const { market, language } = useLocale()
  const { list, session } = useListig()
  const { setVisitedPage, clearVisitedPage, visited } = useVisit()
  const { isVisible: isCookieConsentVisible } = useCookieConsentVisible()
  const [showOnboardingModal, setShowOnboardingModal] = useState(
    !visited.fullServeOnboarding,
  )
  const [showTempSurveyModal, setShowTempSurveyModal] = useState(false)
  const [orderClicked, setOrderClicked] = useState(false)
  const [showExistingOrderModal, setShowExistingOrderModal] = useState(
    !visited.fullServeExistingOrder,
  )

  const isCaptureEmailSMS = useCaptureEmailSms()
  const isCaptureProfile = useCaptureProfile()
  const isTimeSlotManagement = useTimeSlotManagement()
  const isEditOrdersEnabled = useEditOrders()

  const { activeUnpaidOrder } = useInstoreOrders()

  const productsInList = props.products
  const nbrOfItems =
    productsInList.length + (list?.listigOrdersItems?.length ?? 0)

  const shouldAddToExistingOrder =
    activeUnpaidOrder && isEditOrdersEnabled && productsInList.length >= 1

  const itemsInActiveOrder =
    useGetOrder(
      activeUnpaidOrder?.orderNo,
      activeUnpaidOrder?.orderNoSource,
      market,
      language,
      session.businessUnitCode,
    ).order?.items ?? []

  const { data: timeSlots, isLoading: isTimeSlotsLoading } = useGetTimeSlots(
    session.businessUnitCode,
    shouldAddToExistingOrder
      ? [...itemsInActiveOrder, ...productsInList]
      : productsInList,
  )
  const noTimeSlots =
    isTimeSlotManagement && !isTimeSlotsLoading && !timeSlots?.length

  const {
    createCcOrder,
    cancelCcOrder,
    editCcOrder,
    orderNotification,
    orderCreationError,
    isOrderCreationLoading,
    orderRestriction,
  } = useCcOrder(undefined, noTimeSlots)

  const isAnyOrderLoading = useIsFetching({
    queryKey: ["order status"],
  })

  const showPrompt = orderClicked

  const queryClient = useQueryClient()

  const onOrderConfirmationClick = (
    notification?: {
      contactMethodType: string
      contactMethodData: string
    },
    customer?: CreateCashCarryOrderBody["customer"],
    timeSlotId?: string,
  ) => {
    const isIsom = isIsomFullServe(
      market,
      session.businessUnitCode ?? "",
      session.source?.type === "kiosk" ? session.source.ukid : "",
    )
    if (isIsom && activeUnpaidOrder && list?.items && isEditOrdersEnabled) {
      sendOrderButtonEditClickedEvent(activeUnpaidOrder.orderNo)
      editCcOrder({ order: activeUnpaidOrder, items: list.items })
      return
    }
    sendOrderButtonConfirmedEvent(productsInList)
    createCcOrder(
      {
        onSuccess: (data) => {
          const vpcCodes = getListVpcCodes(productsInList)

          sendOrderCreatedEvent(
            data.orderNo,
            data.orderNoSource,
            props.products,
            !!vpcCodes,
            isCaptureProfile,
          )
          clearVisitedPage("fullServeSurvey")
        },
      },
      notification,
      customer,
      timeSlotId,
    )
  }

  const [height, setHeight] = useState<Height>("auto")
  const contentDiv = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const element = contentDiv.current as HTMLDivElement

    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.clientHeight)
    })

    resizeObserver.observe(element)

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <>
      <Notification
        isShowing={orderNotification.isShowing}
        hide={orderNotification.dismiss}
        message={t("FullServe-order-created")}
        undo={undefined}
      />

      <ListSection
        title={t("buy.pay-and-collect")}
        subTitle={
          nbrOfItems
            ? t(nbrOfItems <= 1 ? "common.item" : "common.item_plural", {
                count: nbrOfItems,
              })
            : ""
        }
      >
        <AnimateHeight
          duration={300}
          height={height}
          contentClassName="auto-content"
          contentRef={contentDiv}
          disableDisplayNone
        >
          <div className="space-y-4">
            {productsInList.length > 0 && (
              <div className="flex flex-col divide-y-2 divide-gray-100 bg-white rounded-md">
                <div
                  className="flex justify-between py-8 px-6"
                  data-cy="collect-after-payment"
                >
                  <p className="text-sm">
                    <Trans
                      className="text-base"
                      i18nKey={t(
                        productsInList.length === 1
                          ? "mobile.after-paid"
                          : "mobile.after-paid_plural",
                      )}
                    />
                  </p>
                  <div className="pl-4">
                    <SSRIcon
                      paths={questionMark}
                      onClick={() => {
                        sendOpenFullserveOnboardingEvent()
                        setShowOnboardingModal(true)
                      }}
                    />
                  </div>
                </div>

                <FullServeProducts products={productsInList} />

                {(!!orderCreationError || orderRestriction) && (
                  <div className="bg-white">
                    <div className="py-8">
                      <FullServeErrorMessage
                        errorMessage={orderRestriction ?? "API_ERROR"}
                      />
                    </div>
                  </div>
                )}

                <FullServeButton
                  disabled={!!orderRestriction}
                  text={t("mobile.order-now")}
                  loading={
                    isOrderCreationLoading ||
                    !!isAnyOrderLoading ||
                    (isTimeSlotManagement && isTimeSlotsLoading)
                  }
                  onClick={() => {
                    queryClient.invalidateQueries({
                      queryKey: [
                        session.businessUnitCode,
                        formatCcOrderItems(props.products).map((item) => ({
                          itemId: item.itemId,
                          quantity: item.quantity,
                        })),
                        market,
                        language,
                      ],
                    })

                    sendOrderButtonClickedEvent()
                    if (activeUnpaidOrder) {
                      setShowExistingOrderModal(true)
                    }
                    setOrderClicked(true)
                  }}
                />
              </div>
            )}

            {list?.orders
              ?.slice()
              .reverse()
              .map((order) => (
                <OrderCard
                  key={order.orderNo}
                  listigOrder={order}
                  isOrderCreationLoading={isOrderCreationLoading}
                  onCancelOrder={(order) => cancelCcOrder(order)}
                />
              ))}
          </div>
        </AnimateHeight>

        {market === "CN" && (
          <div>
            <InfoBox
              header={t("services.need-services-text")}
              text={t("services.book-today-text")}
            />
          </div>
        )}
      </ListSection>

      {!isCookieConsentVisible && (
        <FullServeOnboardingModal
          products={productsInList}
          onClose={() => {
            setVisitedPage("fullServeOnboarding")
            setShowOnboardingModal(false)
          }}
          showing={showOnboardingModal}
        />
      )}

      {shouldAddToExistingOrder ? (
        <AddToExistingOrderModal
          products={productsInList}
          orderNo={activeUnpaidOrder.orderNo}
          onClose={() => {
            setVisitedPage("fullServeExistingOrder")
            setShowExistingOrderModal(false)
            setOrderClicked(false)
          }}
          onContinue={(timeSlot?: TimeSlot) => {
            editCcOrder({
              order: activeUnpaidOrder,
              items: productsInList,
              timeSlot,
            })
            setVisitedPage("fullServeExistingOrder")
            setShowExistingOrderModal(false)
            setOrderClicked(false)
          }}
          entryPoint={showPrompt ? "list" : "handover"}
          showing={showExistingOrderModal}
          orderTimeSlotId={activeUnpaidOrder.timeSlot?.id}
          timeSlots={timeSlots ?? null}
        />
      ) : isCaptureProfile ? (
        <CaptureProfileModal
          show={showPrompt}
          onContinue={(customer) => {
            onOrderConfirmationClick(undefined, customer)
            setOrderClicked(false)
          }}
          onCancel={() => setOrderClicked(false)}
          market={market}
          language={language}
        />
      ) : (
        <OrderConfirmationPrompt
          show={showPrompt}
          onContinue={(notification, timeSlotId) => {
            onOrderConfirmationClick(notification, undefined, timeSlotId)
            setOrderClicked(false)
            setShowTempSurveyModal(true)
          }}
          onCancel={() => setOrderClicked(false)}
          isCaptureEmailSMS={isCaptureEmailSMS}
          market={market}
          language={language}
          timeSlots={timeSlots ?? null}
        />
      )}

      {showTempSurveyModal &&
        SHOW_COWORKER_SURVEY_STORES.includes(list?.businessUnit.code ?? "") &&
        !HIDE_COWORKER_SURVEY.includes(market) && (
          <SurveyPrompt show onContinue={() => setShowTempSurveyModal(false)} />
        )}
    </>
  )
}
