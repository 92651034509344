import { postKepsEvent } from "@/api/analytics/keps"
import type { AnalyticsEvent as KepsAnalyticsEvent } from "@upptacka/keps-schema"
import { getAnalyticsTimestamps } from "@upptacka/keps-schema"
import secureStorage from "localstorage-slim"
import platform from "platform"
import { v4 as uuidv4 } from "uuid"
export type PartialKepsAnalyticsEvent = Partial<KepsAnalyticsEvent>

let isInitialized = false

const analyticsQueue: [
  event: KepsEventParams,
  event_expiration_date?: string,
][] = []

type CoreEventDetails = Pick<
  KepsAnalyticsEvent,
  "schema_version" | "schema_type" | "event_meta" | "client_meta" | "timezone"
>

let coreEventDetails: CoreEventDetails

type InitializeAnalyticsProps = {
  market: string
  language: string
  storeNo: string
  listId: string
  timezone?: string
}

/**
 * Initializes analytics for sending keps events
 * and sets the necessary event details
 * @param mandatoryAnalyticsDetails
 */
export function initializeAnalytics({
  market,
  language,
  storeNo,
  listId,
  timezone,
}: InitializeAnalyticsProps) {
  if (isInitialized) return

  coreEventDetails = {
    schema_version: "1.0.0",
    schema_type: "analytics",
    timezone,
    event_meta: {
      session_id: getKepsSessionId(),
      list_id: listId,
      store_no: storeNo,
      app: "upptacka_mobile",
      app_version: process.env.INSTORE_VERSION || "development",
      app_language: language,
      market,
    },
    client_meta: {
      client_browser: platform.name,
      client_browser_version: platform.version,
      client_os: platform.os?.family || platform.product,
      client_language: navigator.language,
      client_resolution: `${window.innerWidth}x${window.innerHeight}`,
      client_id: getKepsDeviceId(),
    },
  }
  isInitialized = true

  analyticsQueue.forEach((eventArgs) => {
    sendKepsEvent(...eventArgs)
  })
}

type KepsEventParams = Omit<
  KepsAnalyticsEvent,
  keyof (CoreEventDetails &
    Pick<
      KepsAnalyticsEvent,
      "timestamp_local" | "timestamp_utc" | "event_sequence_no"
    >)
>

/**
 * Sends a keps event
 * @param event KepsEventParams
 * @returns nothing
 */
export async function sendKepsEvent(
  event: KepsEventParams,
  event_expiration_date?: string,
): Promise<void> {
  if (!isInitialized) {
    analyticsQueue.push([event, event_expiration_date])
    return
  }

  const event_meta = coreEventDetails.event_meta
  if (event_expiration_date) {
    event_meta.temporary_event_expiration_date = new Date(
      event_expiration_date,
    ).toISOString()
  }

  const eventBody: KepsAnalyticsEvent = {
    ...event,
    ...coreEventDetails,
    event_meta,
    event_sequence_no: getSequenceNo(getKepsSessionId()),
    ...getAnalyticsTimestamps(),
    timezone: coreEventDetails.timezone,
  }

  await postKepsEvent(eventBody)
}

export function disableAnalytics() {
  isInitialized = false
}

const KEPS_DEVICE_ID_CACHE_KEY = "keps-device-id"
/**
 * Generates a unique device id to be used with analytics and caches it.
 */
function getKepsDeviceId(): string {
  const cachedId = secureStorage.get<string>(KEPS_DEVICE_ID_CACHE_KEY)
  if (cachedId && typeof cachedId === "string") return cachedId

  const deviceId = uuidv4()

  secureStorage.set(KEPS_DEVICE_ID_CACHE_KEY, deviceId)
  return deviceId
}

const KEPS_SESSION_ID_CACHE_KEY = "keps-session-id-v2"
const FOUR_HOURS = 4 * 60 * 60
/**
 * Generates a unique device id to be used with analytics and caches it.
 */
function getKepsSessionId(): string {
  const cachedId = secureStorage.get<string>(KEPS_SESSION_ID_CACHE_KEY)

  if (cachedId && typeof cachedId === "string") {
    return cachedId
  }

  const deviceId = uuidv4()

  secureStorage.set(KEPS_SESSION_ID_CACHE_KEY, deviceId, {
    /** Time to live in seconds */
    ttl: FOUR_HOURS,
  })
  return deviceId
}

const FIRST_SEQUENCE_NO = 1
function getSequenceNo(sessionId: string): number {
  const key = "keps-sequence-no-" + sessionId

  const sequenceNo = secureStorage.get<number>(key) || FIRST_SEQUENCE_NO

  secureStorage.set(key, sequenceNo + 1)

  return sequenceNo
}

export default {
  disableAnalytics,
  initializeAnalytics,
  sendKepsEvent,
}
