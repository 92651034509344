import {
  DepartmentSalesLocation,
  FoodItemSalesLocation,
  PackageInformation,
  SalesLocation,
} from "@/types/responses/find"
import { useTranslation } from "react-i18next"
import { getAisleAndBin } from "@/types/product/products"
import { MissingSalesLocation } from "./MissingSalesLocation"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import { FullServeLocation } from "./FullServeLocation"
import { Fragment } from "react"
import { getProductSalesMethods } from "@/utils/getSalesLocation"
import { SelfServeLocation } from "./SelfServeLocation"
import { DepartmentLocation } from "./DepartmentLocation"
import { capitalize } from "@/config/locales"
import SSRIcon from "@ingka/ssr-icon"
import box from "@ingka/ssr-icon/paths/box"
import { FoodItemLocation } from "./FoodItemLocation"
import questionMark from "@ingka/ssr-icon/paths/question-mark-circle"
import FullServeErrorMessage from "../full-serve/FullServeErrorMessage"
import { useCcOrder } from "@/hooks/useCcOrder"
import { countArticles } from "@/utils/countArticles"
import { PipProduct } from "@/types/product/pip"
import { getFloorNumber } from "@/utils/getFloorNumber"
import { getFloorForChildItems } from "@/utils/getFloorForChildItems"
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter"

type Props = {
  product: PipProduct
  onHelpClick?: () => void
  listView?: boolean
}

export const ProductLocation: React.FC<Props> = ({
  product,
  onHelpClick,
  listView,
}) => {
  const { t } = useTranslation()

  const { orderRestriction } = useCcOrder()

  const { salesMethods, isMultipleItems } = getProductSalesMethods(product)

  const {
    floor: marketHallFloor,
    showFloorInformation: marketHallFloorInformation,
  } = getFloorForChildItems(salesMethods, "market-hall")
  const {
    floor: showroomFloor,
    showFloorInformation: showroomFloorInformation,
  } = getFloorForChildItems(salesMethods, "showroom")

  const {
    floor: selfServeFloor,
    showFloorInformation: selfserveFloorInformation,
  } = getFloorForChildItems(salesMethods, "self-serve")

  const isSplit = !!("isSplit" in product.info && product.info.isSplit)

  // If no article has a sales method, we don't show anything
  const unknownSalesMethod = Object.values(salesMethods).every(
    (method) => method.length === 0,
  )
  const isOnline = salesMethods.online.length > 0
  if (unknownSalesMethod || (isOnline && listView)) return null
  if (listView) {
    return (
      salesMethods["self-serve"].length > 0 && (
        <div className="pb-1 gap-6">
          {salesMethods["self-serve"].map((item) => {
            return (
              <div key={item.info.no}>
                <NumberOfPackages packaging={item.info.packaging} noText />
              </div>
            )
          })}
        </div>
      )
    )
  }

  if (isMultipleItems) {
    return (
      <div className="pb-1">
        {isSplit && (
          <p className="text-sm text-black pb-2">{t("buy.collect-in")}: </p>
        )}

        {/* Full serve */}
        {salesMethods["full-serve"].length > 0 && (
          <div className="gap-6">
            <div className="flex justify-between">
              <FullServeSectionTitle
                title={t("buy.pay-and-collect")}
                subtitle={t("mobile.after-paid-collect-at-pickup-point")}
                amountArticles={t("common.count-articles_plural", {
                  count: countArticles(salesMethods["full-serve"]),
                })}
                onhelpClick={onHelpClick}
              />
            </div>
            {salesMethods["full-serve"].map((item) => (
              <div key={item.info.no}>
                <h4 className="text-xs">
                  {"quantity" in item ? item.quantity : 1} x {item.info.name}
                </h4>

                <FullServeLocation
                  title={capitalize(item.info.description)}
                  articleNo={dottedIdentifier(item.info.no)}
                  location={t("location.pick-up-point")}
                />
                <NumberOfPackages packaging={item.info.packaging} />
              </div>
            ))}
          </div>
        )}

        {/* Showroom section*/}
        {salesMethods["showroom"].length > 0 && (
          <>
            <div className="pb-1 gap-6">
              <SectionTitle
                product={product}
                title={t("location.showroom")}
                floor={
                  showroomFloorInformation
                    ? t("common.level") + showroomFloor
                    : ""
                }
                amountArticles={t("common.count-articles", {
                  count: countArticles(salesMethods["showroom"]),
                })}
                showCta={!isSplit}
              />

              {salesMethods["showroom"].map((item) => (
                <div key={item.info.no}>
                  <h4 className="text-xs">
                    {"quantity" in item ? item.quantity : 1} x {item.info.name}
                  </h4>
                  <div className="flex justify-between">
                    {item.locations?.[0] && (
                      <DepartmentLocation
                        title={item.info.description}
                        articleNo={dottedIdentifier(item.info.no)}
                        department={
                          (item.locations[0] as DepartmentSalesLocation)
                            .departmentName
                        }
                      />
                    )}
                  </div>
                  <NumberOfPackages packaging={item.info.packaging} />
                </div>
              ))}
            </div>
          </>
        )}

        {/* Market-hall section*/}
        {salesMethods["market-hall"].length > 0 && (
          <>
            <div className="pb-1 gap-6">
              <SectionTitle
                title={t("location.market-hall")}
                product={product}
                amountArticles={t("common.count-articles", {
                  count: countArticles(salesMethods["market-hall"]),
                })}
                floor={
                  marketHallFloorInformation
                    ? t("common.level") + marketHallFloor
                    : ""
                }
                showCta={!isSplit}
              />

              {salesMethods["market-hall"].map((item) => {
                return (
                  <div key={item.info.no}>
                    <h4 className="text-xs">
                      {"quantity" in item ? item.quantity : 1} x{" "}
                      {item.info.name}
                    </h4>
                    <div className="flex justify-between">
                      {item.locations?.[0] && (
                        <DepartmentLocation
                          title={item.info.description}
                          articleNo={dottedIdentifier(item.info.no)}
                          department={
                            (item.locations[0] as DepartmentSalesLocation)
                              .departmentName
                          }
                        />
                      )}
                    </div>
                    <NumberOfPackages packaging={item.info.packaging} />
                  </div>
                )
              })}
            </div>
          </>
        )}

        {/* Self serve */}
        {salesMethods["self-serve"].length > 0 && (
          <div className="pb-1 gap-6 ">
            <SectionTitle
              title={t("location.self-serve-area")}
              product={product}
              amountArticles={t("common.count-articles", {
                count: countArticles(salesMethods["self-serve"]),
              })}
              floor={
                selfserveFloorInformation
                  ? t("common.level") + selfServeFloor
                  : ""
              }
              showCta={!isSplit}
            />

            {salesMethods["self-serve"].map((item) => {
              const aisleAndBin = getAisleAndBin(item.locations)
              return (
                <div key={item.info.no}>
                  <h4 className="text-xs">
                    {"quantity" in item ? item.quantity : 1} x {item.info.name}
                  </h4>

                  <SelfServeLocation
                    title={item.info.description}
                    articleNo={dottedIdentifier(item.info.no)}
                    aisle={aisleAndBin?.aisle ?? ""}
                    bin={aisleAndBin?.bin ?? ""}
                  />
                  <NumberOfPackages packaging={item.info.packaging} />
                </div>
              )
            })}
          </div>
        )}

        {/* Online */}
        {salesMethods["online"].length > 0 && (
          <div className="pb-1 gap-6 ">
            <SectionTitle
              title={t("Interstitial-pickup-online")}
              amountArticles={t("common.count-articles_plural", {
                count: countArticles(salesMethods["online"]),
              })}
            />

            {salesMethods["online"].map((item) => {
              return (
                <div key={item.info.no}>
                  <h4 className="text-xs">
                    {"quantity" in item ? item.quantity : 1} x {item.info.name}
                  </h4>

                  <NumberOfPackages packaging={item.info.packaging} />
                </div>
              )
            })}
          </div>
        )}

        {/* Products without sales location */}
        {salesMethods["missing-sales-location"].length > 0 && (
          <div className="pb-1 gap-6">
            <SectionTitle
              title={t("buy.collect-in") + ", "}
              amountArticles={t("common.count-articles", {
                count: countArticles(salesMethods["missing-sales-location"]),
              })}
            />

            {salesMethods["missing-sales-location"].map((item) => (
              <div key={item.info.no}>
                <h4 className="text-xs">
                  {"quantity" in item ? item.quantity : 1} x {item.info.name}
                </h4>

                <MissingSalesLocation
                  articleNo={dottedIdentifier(item.info.no)}
                  title={capitalize(item.info.description)}
                  location={t("common.contact-coworker")}
                />
                <NumberOfPackages packaging={item.info.packaging} />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  } else if (salesMethods["full-serve"][0]) {
    const items = salesMethods["full-serve"][0]

    return (
      <div className="pb-1">
        <div className="flex justify-between">
          <ProductLocationTitle
            salesLocation={salesMethods["full-serve"][0].locations?.[0]}
          />
          <SSRIcon paths={questionMark} onClick={onHelpClick} />
        </div>

        <p className="text-sm font-normal">
          {t("mobile.after-paid-collect-at-pickup-point") + " "}
        </p>
        {orderRestriction && (
          <div className="pt-8">
            <FullServeErrorMessage errorMessage={orderRestriction} />
          </div>
        )}
        <div className="flex justify-between pt-2">
          <FullServeLocation
            articleNo={dottedIdentifier(product.info.no)}
            location={t("location.pick-up-point")}
          />
        </div>
        <NumberOfPackages packaging={items.info.packaging} />
      </div>
    )
  } else if (salesMethods["self-serve"][0]) {
    const items = salesMethods["self-serve"][0]
    const aisleAndBin = getAisleAndBin(items.locations)

    return (
      <div className="pb-1">
        <SectionTitle
          title={t("location.self-serve-area")}
          product={product}
          floor={selfserveFloorInformation ? selfServeFloor : ""}
        />

        <SelfServeLocation
          articleNo={dottedIdentifier(product.info.no)}
          aisle={aisleAndBin?.aisle ?? ""}
          bin={aisleAndBin?.bin ?? ""}
        />
        <NumberOfPackages packaging={items.info.packaging} />
      </div>
    )
  } else if (salesMethods["missing-sales-location"][0]) {
    return (
      <div className="pb-5">
        <ProductLocationTitle />
        <MissingSalesLocation
          articleNo={dottedIdentifier(product.info.no)}
          title={capitalize(product.info.description)}
          location={t("common.contact-coworker")}
        />
      </div>
    )
  } else if (salesMethods["food-market"][0]) {
    const item = salesMethods["food-market"][0]
    const location = item.locations?.[0] as FoodItemSalesLocation

    return (
      <div className="pb-5">
        <ProductLocationTitle salesLocation={location} />
        <FoodItemLocation articleNo={dottedIdentifier(product.info.no)} />
      </div>
    )
  } else {
    const item = salesMethods["showroom"][0] ?? salesMethods["market-hall"][0]
    if (!item) return null

    const location = item.locations?.[0] as DepartmentSalesLocation
    if (!location) return null

    const salesLocationId = location.salesLocationId
    const showFloorInformation = salesLocationId?.toUpperCase().startsWith("F")
    const showroomOrMarketHallFloor =
      salesLocationId && showFloorInformation
        ? getFloorNumber(salesLocationId)
        : ""

    return (
      <div className="pb-1">
        <SectionTitle
          title={
            salesMethods["showroom"]
              ? t("location.showroom")
              : t("location.market-hall")
          }
          product={product}
          floor={
            showFloorInformation
              ? t("common.level") + showroomOrMarketHallFloor
              : ""
          }
        />
        <DepartmentLocation
          articleNo={dottedIdentifier(product.info.no)}
          department={location.departmentName}
        />
        <NumberOfPackages packaging={item.info.packaging} />
      </div>
    )
  }
}

const ProductLocationTitle: React.FC<{
  salesLocation?: SalesLocation
}> = ({ salesLocation }) => {
  const { t } = useTranslation()

  const title = (() => {
    switch (salesLocation?.salesMethod) {
      case "FULL_SERVE":
        return t("buy.pay-and-collect")
      case "SWEDISH_FOOD_MARKET":
        return t("buy.collect-in") + " " + t("location.swedish-food-market")
      default:
        return t("common.collect")
    }
  })()

  return (
    <div className="flex gap-x-2 pb-2">
      <h4 className="text-sm font-bold">{title}</h4>
    </div>
  )
}

export const NumberOfPackages = ({
  packaging,
  noText,
}: {
  packaging: PackageInformation
  noText?: boolean
}) => {
  const { t } = useTranslation()
  const nbrOfPackages = packaging.count
  const numbers = Array.from({ length: nbrOfPackages }, (_, i) => i + 1)

  return noText ? (
    <>
      {nbrOfPackages > 1 && (
        <>
          <span className="mt-1">
            {nbrOfPackages > 1
              ? t("Interstitial-multi-package-notification-title", {
                  count: nbrOfPackages,
                })
              : t("common.package").trim() + ": " + packaging.packages[0]?.text}
          </span>
          <div className="flex gap-1 mt-1" data-cy="multiple-packages">
            {t("common.collect")}
            {numbers.map((number, index) => (
              <Fragment key={index}>
                {index === numbers.length - 1 && t("common.and")}
                <span className="mb-1 flex h-5 w-5 items-center justify-center border border-gray-400 bg-yellow-brand text-xs">
                  {number}
                </span>
              </Fragment>
            ))}
          </div>
        </>
      )}
    </>
  ) : (
    nbrOfPackages >= 1 && (
      <div className="pb-4 pt-2 text-sm">
        <div className="flex items-center text-xs mb-1">
          <SSRIcon paths={box} className="text-black" />
          <span className="ml-1">
            {nbrOfPackages > 1
              ? t("Interstitial-multi-package-notification-title", {
                  count: nbrOfPackages,
                })
              : t("common.package").trim() + ": " + packaging.packages[0]?.text}
          </span>
        </div>

        {nbrOfPackages > 1 && (
          <div className="flex items-center ml-1">
            <div className="pre-wrap">
              {Array.from(new Array(nbrOfPackages)).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                  <div className="flex">
                    <span className="mb-1 flex h-5 w-5 items-center justify-center border border-gray-400 bg-yellow-brand text-xs">
                      {index + 1}
                    </span>
                    <span className="text-xs ml-2">
                      {t("common.package").trim()}:{" "}
                      {packaging.packages[index]?.text}
                    </span>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  )
}

interface SectionTitleProps {
  title: string
  product?: PipProduct
  amountArticles?: string
  subtitle?: string
  floor?: string
  showCta?: boolean
  onhelpClick?: () => void
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  amountArticles,
  product,
  floor,
  showCta,
}) => {
  const { t } = useTranslation()
  const capitalizedFloor = floor ? capitalizeFirstLetter(floor) : ""

  return (
    <div className="flex flex-col">
      <div className="pb-2">
        {product?.info?.type !== "ART" ? (
          <>
            {showCta && (
              <p className="text-sm text-black pb-2">{t("buy.collect-in")}: </p>
            )}
            <h4 className="text-base">
              {title}
              {floor ? ", " + capitalizedFloor + ", " : ""}{" "}
              <span className="text-base text-gray-500 font-normal">
                {amountArticles ?? ""}
              </span>
            </h4>
          </>
        ) : (
          <h4 className="text-base">
            <span className="text-base text-gray-500 font-normal">
              {t("buy.collect-in")}:{" "}
            </span>
            {title}
            {floor ? ", " + capitalizedFloor : ""}
          </h4>
        )}
      </div>
    </div>
  )
}

interface FullServeSectionTitleProps {
  title: string
  amountArticles: string
  subtitle?: string
  onhelpClick?: () => void
}

const FullServeSectionTitle: React.FC<FullServeSectionTitleProps> = ({
  title,
  amountArticles,
  subtitle,
  onhelpClick,
}) => (
  <div className="flex flex-col pb-6">
    <div className="flex justify-between pb-2 items-center">
      <h4 className="text-sm">{title}</h4>
      {onhelpClick && (
        <SSRIcon
          className="flex-shrink-0 ml-4"
          paths={questionMark}
          onClick={onhelpClick}
        />
      )}
    </div>
    {subtitle && <p className="text-sm">{subtitle}</p>}
    <p className="text-sm">{amountArticles}</p>
  </div>
)
