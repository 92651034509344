import Button from "@ingka/button"
import { ModalFooter } from "@ingka/modal"
import { useTranslation } from "react-i18next"
import { SlideUpModal } from "../modals/SlideUpModal"
import InputField from "@ingka/input-field"
import FormField from "@ingka/form-field"
import { useEffect, useState } from "react"
import { PrivacyPolicyModal } from "../modals/PrivacyPolicyModal"
import { getValidationRulesByField } from "@ingka-group-digital/customer-fields-validation-rules/dist/validator/validationRulesExtractor"
import { CreateCashCarryOrderBody } from "@/types/responses/buy"
import {
  validateField,
  validatePanField,
  ValidationRule,
} from "@/utils/validateField"
import PhoneInput, {
  Country,
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input/input"
import { useListig } from "@/hooks/useListig"
import { categorizeProducts } from "@/utils/categorizeProducts"
import { calculatePriceTotals } from "@/utils/calculatePriceTotals"
import { useGetCompleteAddress } from "@/hooks/useGetCompleteAddress"

/**
 * Prompt to collect customer profile information for full serve order with tax point delivery
 */
export const CaptureProfileModal: React.FC<{
  show: boolean
  onContinue: (customer?: CreateCashCarryOrderBody["customer"]) => void
  onCancel: () => void
  market: string
  language: string
}> = ({ show, onContinue, onCancel, market, language }) => {
  const { t } = useTranslation()

  const [showPolicy, setShowPolicy] = useState<boolean>(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [mobile, setMobile] = useState("")
  const [email, setEmail] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [addressLine3, setAddressLine3] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [pan, setPan] = useState("")

  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true)
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true)
  const [isMobileValid, setIsMobileValid] = useState<boolean>(true)
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
  const [isAddressLine1Valid, setAddressLine1Valid] = useState<boolean>(true)
  const [isAddressLine2Valid, setAddressLine2Valid] = useState<boolean>(true)
  const [isAddressLine3Valid, setAddressLine3Valid] = useState<boolean>(true)
  const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(true)
  const [isCityValid, setIsCityValid] = useState<boolean>(true)
  const [isPanValid, setIsPanValid] = useState<boolean>(true)

  const [shouldValidateFirstName, setShouldValidateFirstName] = useState(false)
  const [shouldValidateLastName, setShouldValidateLastName] = useState(false)
  const [shouldValidateMobile, setShouldValidateMobile] = useState(false)
  const [shouldValidateAddressLine1, setShouldValidateAddressLine1] =
    useState(false)
  const [shouldValidateAddressLine2, setShouldValidateAddressLine2] =
    useState(false)
  const [shouldValidateAddressLine3, setShouldValidateAddressLine3] =
    useState(false)
  const [shouldValidateEmail, setShouldValidateEmail] = useState(false)
  const [shouldValidatePostalCode, setShouldValidatePostalCode] =
    useState(false)
  const [shouldValidateCity, setShouldValidateCity] = useState(false)
  const [shouldValidatePan, setShouldValidatePan] = useState(false)

  const listig = useListig()
  const fullServeProducts = categorizeProducts(listig.list).FULL_SERVE
  const totalPrice = calculatePriceTotals(fullServeProducts)

  // Pan field is required when total price is greater than or equal to 200000
  const showPanField = totalPrice.regular.inclTax >= 200000

  const { data: completeAddress } = useGetCompleteAddress(
    market,
    language,
    postalCode,
  )

  const validationRules = getValidationRulesByField(
    [
      "FIRST_NAME",
      "LAST_NAME",
      "MOBILE",
      "ADDRESS_LINE1",
      "ADDRESS_LINE2",
      "ADDRESS_LINE3",
      "POST_CODE",
      "CITY",
      "EMAIL",
    ],
    market,
  ) as ValidationRule[]

  const packageCountryCode = getCountryCallingCode(market as Country)

  // Reset city and state when postalCode changes
  useEffect(() => {
    setCity("")
    setState("")
  }, [postalCode])

  const validateAllFields = () => {
    const firstNameValid = validateField(
      firstName,
      "firstName",
      validationRules,
    )
    const lastNameValid = validateField(lastName, "lastName", validationRules)
    const emailValid = validateField(email, "email", validationRules)
    const mobileValid = isValidPhoneNumber(mobile, market as Country)
    const addressLine1Valid = validateField(
      addressLine1,
      "addressLine1",
      validationRules,
    )
    const addressLine2Valid =
      addressLine2.trim() === "" ||
      validateField(addressLine2, "addressLine2", validationRules)
    const addressLine3Valid =
      addressLine3.trim() === "" ||
      validateField(addressLine3, "addressLine3", validationRules)
    const postalCodeValid = validateField(
      postalCode,
      "zipCode",
      validationRules,
    )
    const cityValid = validateField(city, "city", validationRules)
    const panValid = validatePanField(pan)

    setIsFirstNameValid(firstNameValid)
    setIsLastNameValid(lastNameValid)
    setIsMobileValid(mobileValid)
    setIsEmailValid(emailValid)
    setAddressLine1Valid(addressLine1Valid)
    setAddressLine2Valid(addressLine2Valid)
    setAddressLine3Valid(addressLine3Valid)
    setIsPostalCodeValid(postalCodeValid)
    setIsCityValid(cityValid)
    setIsPanValid(panValid)

    return {
      firstNameValid,
      lastNameValid,
      mobileValid,
      emailValid,
      addressLine1Valid,
      addressLine2Valid,
      addressLine3Valid,
      postalCodeValid,
      cityValid,
      panValid,
    }
  }

  const handleProfileSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const {
      firstNameValid,
      lastNameValid,
      mobileValid,
      emailValid,
      addressLine1Valid,
      addressLine2Valid,
      addressLine3Valid,
      postalCodeValid,
      cityValid,
      panValid,
    } = validateAllFields()

    if (
      firstNameValid &&
      lastNameValid &&
      mobileValid &&
      emailValid &&
      addressLine1Valid &&
      addressLine2Valid &&
      addressLine3Valid &&
      postalCodeValid &&
      cityValid &&
      (!showPanField || panValid)
    ) {
      onContinue({
        firstName,
        lastName,
        mobileNumber: mobile,
        emailAddress: email,
        addressLine1,
        addressLine2,
        addressLine3,
        zipCode: postalCode,
        city,
        countryCode: market,
        stateCode: completeAddress?.stateCode ?? "",
        pan: showPanField ? pan : undefined,
      })
    } else {
      setShouldValidateFirstName(true)
      setShouldValidateLastName(true)
      setShouldValidateMobile(true)
      setShouldValidateEmail(true)
      setShouldValidateAddressLine1(true)
      setShouldValidateAddressLine2(true)
      setShouldValidateAddressLine3(true)
      setShouldValidatePostalCode(true)
      setShouldValidateCity(true)
      if (showPanField) {
        setShouldValidatePan(true)
      }
    }
  }

  const handleBlur = (field: string) => {
    switch (field) {
      case "firstName":
        setShouldValidateFirstName(true)
        setIsFirstNameValid(
          validateField(firstName, "firstName", validationRules),
        )
        break
      case "lastName":
        setShouldValidateLastName(true)
        setIsLastNameValid(validateField(lastName, "lastName", validationRules))
        break
      case "mobile":
        setShouldValidateMobile(true)
        setIsMobileValid(isValidPhoneNumber(mobile, market as Country))
        break
      case "email":
        setShouldValidateEmail(true)
        setIsEmailValid(validateField(email, "email", validationRules))
        break
      case "addressLine1":
        setShouldValidateAddressLine1(true)
        setAddressLine1Valid(
          validateField(addressLine1, "addressLine1", validationRules),
        )
        break
      case "addressLine2":
        setShouldValidateAddressLine2(true)
        if (addressLine2.trim() === "") {
          setAddressLine2Valid(true) // Consider empty addressLine2 as valid
        } else {
          setAddressLine2Valid(
            validateField(addressLine2, "addressLine2", validationRules),
          )
        }
        break
      case "addressLine3":
        setShouldValidateAddressLine3(true)
        if (addressLine3.trim() === "") {
          setAddressLine3Valid(true) // Consider empty addressLine3 as valid
        } else {
          setAddressLine3Valid(
            validateField(addressLine3, "addressLine3", validationRules),
          )
        }
        break
      case "postalCode":
        setShouldValidatePostalCode(true)
        setIsPostalCodeValid(
          validateField(postalCode, "zipCode", validationRules) &&
            !!completeAddress?.state,
        )
        if (completeAddress) {
          setCity(completeAddress.city)
          setState(completeAddress.state)
        }
        break
      case "city":
        setShouldValidateCity(true)
        setIsCityValid(validateField(city, "city", validationRules))
        break
      case "pan":
        setShouldValidatePan(true)
        setIsPanValid(validatePanField(pan))
        break
      default:
        break
    }
  }

  return (
    <SlideUpModal onClose={onCancel} showing={show}>
      <h2 className="text-2xl mb-12" data-cy="modal-header">
        {t("mobile.ready-to-order")}

        <p className="text-sm font-thin pt-3">
          {t("mobile.profile-capture-info")}
        </p>
      </h2>

      <>
        <form onSubmit={handleProfileSubmit}>
          <div className="space-y-4">
            <FormField
              shouldValidate={shouldValidateFirstName}
              valid={isFirstNameValid}
              validation={{
                msg:
                  firstName === ""
                    ? "First name is mandatory"
                    : "Your First name can't contain numbers or special characters",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                label={"First name" + " *"}
                id="first-name"
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                  setShouldValidateFirstName(false)
                }}
                onBlur={() => handleBlur("firstName")}
                inputMode="text"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateLastName}
              valid={isLastNameValid}
              validation={{
                msg:
                  lastName === ""
                    ? "Last name is mandatory"
                    : "Your Last name can't contain numbers or special characters",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="last-name"
                label={"Last name" + " *"}
                type="text"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                  setShouldValidateLastName(false)
                }}
                onBlur={() => handleBlur("lastName")}
                inputMode="text"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateMobile}
              valid={isMobileValid}
              validation={{
                msg:
                  mobile === ""
                    ? "Mobile phone is mandatory"
                    : "Your mobile phone number is not valid",
                id: "profile",
                type: "error",
              }}
            >
              <div className="form-group">
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium text-gray-700 pb-1"
                >
                  Mobile phone *
                </label>
                <PhoneInput
                  placeholder={`+${packageCountryCode}`}
                  id="mobile"
                  onChange={(value) => {
                    setMobile(value || "")
                    setShouldValidateMobile(false)
                  }}
                  defaultCountry={market as Country}
                  value={mobile}
                  onBlur={() => handleBlur("mobile")}
                  className={`phone-number-input w-full border p-3 placeholder-gray-200 rounded ${
                    shouldValidateMobile
                      ? isMobileValid
                        ? "border-green-700 focus:border-2"
                        : "border-red-500 focus:border-red-600 focus:border-2"
                      : "border-gray-400 focus:border-gray-500 focus:border-2"
                  }`}
                />
              </div>
            </FormField>

            <FormField
              shouldValidate={shouldValidateEmail}
              valid={isEmailValid}
              validation={{
                msg:
                  email === ""
                    ? "Email is mandatory"
                    : "Your email address is not valid",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="email"
                label={"Email" + " *"}
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setShouldValidateEmail(false)
                }}
                onBlur={() => handleBlur("email")}
                inputMode="email"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateAddressLine1}
              valid={isAddressLine1Valid}
              validation={{
                msg:
                  addressLine1 === ""
                    ? "Addressline 1 is mandatory"
                    : "This is not a valid address",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="address-line-1"
                label={"Address line 1" + " *"}
                type="text"
                value={addressLine1}
                onChange={(e) => {
                  setAddressLine1(e.target.value)
                  setShouldValidateAddressLine1(false)
                }}
                onBlur={() => handleBlur("addressLine1")}
                inputMode="text"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateAddressLine2}
              valid={isAddressLine2Valid}
              validation={{
                msg: "This is not a valid address",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="address-line-2"
                label="Address line 2"
                type="text"
                value={addressLine2}
                onChange={(e) => {
                  setAddressLine2(e.target.value)
                  setShouldValidateAddressLine2(false)
                }}
                onBlur={() => handleBlur("addressLine2")}
                inputMode="text"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateAddressLine3}
              valid={isAddressLine3Valid}
              validation={{
                msg: "This is not a valid address",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="address-line-3"
                label="Address line 3"
                type="text"
                value={addressLine3}
                onChange={(e) => {
                  setAddressLine3(e.target.value)
                  setShouldValidateAddressLine3(false)
                }}
                onBlur={() => handleBlur("addressLine3")}
                inputMode="text"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidatePostalCode}
              valid={isPostalCodeValid}
              validation={{
                msg:
                  postalCode === ""
                    ? "Postal code is mandatory"
                    : "This is not a valid postal code",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="postal-code"
                label={"Postal code" + " *"}
                type="text"
                value={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value)
                  setShouldValidatePostalCode(false)
                }}
                onBlur={() => handleBlur("postalCode")}
                inputMode="text"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateCity}
              valid={isCityValid}
              validation={{
                msg:
                  city === ""
                    ? "City is mandatory"
                    : "This is not a valid City name",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="city"
                label={"City" + " *"}
                type="text"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value)
                  setShouldValidateCity(false)
                }}
                onBlur={() => handleBlur("city")}
                inputMode="text"
              />
            </FormField>

            <FormField>
              <InputField
                id="state"
                readOnly
                label={"State" + " *"}
                type="text"
                value={state}
                onChange={(e) => {
                  setState(e.target.value)
                }}
                onBlur={() => handleBlur("state")}
                inputMode="text"
              />
            </FormField>

            {showPanField && (
              <FormField
                shouldValidate={shouldValidatePan}
                valid={isPanValid}
                validation={{
                  msg:
                    pan === "" ? "PAN is mandatory" : "This is not a valid PAN",
                  id: "profile",
                  type: "error",
                }}
              >
                <InputField
                  id="pan"
                  label={"PAN" + " *"}
                  type="text"
                  value={pan}
                  onChange={(e) => {
                    setPan(e.target.value)
                    setShouldValidatePan(false)
                  }}
                  onBlur={() => handleBlur("pan")}
                  inputMode="text"
                />
              </FormField>
            )}
          </div>
        </form>

        <div className="pt-7">
          <p className="text-xs text-gray-400 mt-0 mb-10">
            {t("mobile.show-privacy-policy")}&nbsp;
            <span onClick={() => setShowPolicy(true)} className="underline">
              {t("common.privacy-policy-anchor-text")}
            </span>
          </p>
          <PrivacyPolicyModal
            showPolicy={showPolicy}
            onClose={() => setShowPolicy(false)}
            market={market}
            language={language}
          />
        </div>
      </>

      <ModalFooter className="sticky bottom-0 bg-white z-10 px-0 py-4">
        <Button text={t("mobile.not-yet")} onClick={onCancel} />
        <Button
          data-cy="confirm-order-button"
          type="emphasised"
          text={t("mobile.place-order")}
          htmlType="submit"
          onClick={(e: React.MouseEvent) => {
            handleProfileSubmit(e)
          }}
        />
      </ModalFooter>
    </SlideUpModal>
  )
}
