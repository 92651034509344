import { Choice, ChoiceItem } from "@ingka/choice"
import { TimeSlot } from "@/types/order"
import Button from "@ingka/button"
import checkmark from "@ingka/ssr-icon/paths/checkmark"
import { getTimeFromLocalDateTimeWithoutTimezone } from "@/utils/formatDateTime"
import { useTranslation } from "react-i18next"
import { sendTimeSlotContinueEvent } from "@/analytics/events/sendTimeSlotContinueEvent"
import { sendTimeSlotSelectEvent } from "@/analytics/events/sendTimeSlotSelectEvent"

type TimeSlotsProps = {
  selectedTimeSlotId?: string
  setSelectedTimeSlotId: (id: string) => void
  timeSlots: TimeSlot[]
  onContinue: (id: string) => void
  buttonText: string
}

export const TimeSlots: React.FC<TimeSlotsProps> = ({
  selectedTimeSlotId,
  setSelectedTimeSlotId,
  timeSlots,
  onContinue,
  buttonText,
}) => {
  const { t } = useTranslation()

  function getSelectedTimeSlotIndex() {
    if (!selectedTimeSlotId) return 0

    const index = timeSlots.findIndex(
      (timeSlot) => timeSlot.id === selectedTimeSlotId,
    )

    if (index === -1) return 0

    return index
  }

  return (
    <>
      <div className="text-2xl font-bold text-black pb-6">
        {t("mobile.choose-collection-time")}
      </div>
      <Choice
        onClick={(e) => e.stopPropagation()}
        subtle
        preselectedIndex={getSelectedTimeSlotIndex()}
      >
        {timeSlots.map((timeSlot, index) => (
          <ChoiceItem
            key={index}
            title={
              getTimeFromLocalDateTimeWithoutTimezone(timeSlot.fromDateTime) +
              " - " +
              getTimeFromLocalDateTimeWithoutTimezone(timeSlot.toDateTime)
            }
            caption={
              selectedTimeSlotId === timeSlot.id
                ? t("mobile.pay-before", {
                    time: getTimeFromLocalDateTimeWithoutTimezone(
                      timeSlot.paymentCutOffDateTime,
                    ),
                  })
                : ""
            }
            id={timeSlot.id}
            imagePosition="trailing"
            ssrIcon={selectedTimeSlotId === timeSlot.id ? checkmark : undefined}
            onClick={(id: string) => {
              setSelectedTimeSlotId(id)
              sendTimeSlotSelectEvent(timeSlot, index, timeSlots.length)
            }}
          />
        ))}
      </Choice>
      <Button
        type="primary"
        className="w-full"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          sendTimeSlotContinueEvent()
          onContinue(selectedTimeSlotId ?? "")
        }}
      >
        {buttonText}
      </Button>
    </>
  )
}
